import { AxiosResponse } from 'axios';
import { axiosPrivate, ResultList } from './api.service';

export interface Artifact {
  id: string;
  name: string;
  description: string;
  externalLink?: string;
  owner: string;
  thumb?: { name: string; mime: string; url?: string };
  file?: { name: string; mime: string; url?: string };
  tags: [string];
}

class ArtifactService {
  static getArtifactFile(id: string): Promise<{ blob: Blob; fileName: string }> {
    return axiosPrivate
      .get(`artifacts/${id}/file`, {
        responseType: 'blob',
      })
      .then((response) => {
        const contentDisposition = response.headers['content-disposition'] as string;
        let filename = '';
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(contentDisposition);
        if (matches && matches[1]) {
          filename = matches[1].replace(/['"]/g, '');
        }
        return { blob: response.data as Blob, fileName: filename };
      });
  }

  static getArtifactThumb(id: string): Promise<{ blob: Blob; fileName: string }> {
    return axiosPrivate
      .get(`artifacts/${id}/thumb`, {
        responseType: 'blob',
      })
      .then((response) => {
        const contentDisposition = response.headers['content-disposition'] as string;
        let filename = '';
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(contentDisposition);
        if (matches && matches[1]) {
          filename = matches[1].replace(/['"]/g, '');
        }
        return { blob: response.data as Blob, fileName: filename };
      });
  }

  // Get list of artifacts
  // @param name - Name of the artifact to use as a filter
  // @param tags - Array of tags to use as a filter (OR)
  // @param ticket - TicketID to use as a filter
  // @param sortField - Field to sort results by
  // @param sortOrder - Order of sorting (only if sortField supplied)
  // @limit - Maximum number of results per page
  // @page - Page of results to return
  static async getArtifacts(
    name?: string,
    tags?: string[],
    limit?: number,
    page?: number,
    sortField?: string,
    sortOrder = 'asc'
  ): Promise<ResultList<Artifact>> {
    return axiosPrivate
      .get<unknown, AxiosResponse<ResultList<Artifact>, unknown>, unknown>('artifacts', {
        params: {
          name: name || undefined,
          tags: tags || undefined,
          sortBy: sortField ? `${sortField}:${sortOrder}` : undefined,
          limit: limit || undefined,
          page: page || undefined,
        },
      })
      .then((result) => {
        return result.data;
      });
  }

  // Create a new Artifact with the given name and description
  // @param name - Name of the artifact
  // @param description - Description of the artifact
  // @param externalLink - URI for an externally hosted artifact (e.g. YouTube video)
  // @param tags - Array of tags associated with this artifact
  // @param file - URI from file-picker for the artifact file
  // @param thumb - URI from file-picker for the artifact thumbnail
  // @param owner - Owner of the artifact (only settable by Admins)
  static async createArtifact(
    name: string,
    description: string,
    externalLink?: string,
    tags?: string[],
    file?: File,
    thumb?: File,
    owner?: string
  ) {
    const options = { headers: {} };
    if (file || thumb) {
      options.headers = {
        'Content-Type': 'multipart/form-data',
      };
    }

    return axiosPrivate
      .post<Artifact, AxiosResponse<Artifact, unknown>, unknown>(
        'artifacts',
        {
          name,
          description,
          externalLink: externalLink || undefined,
          tags: tags || undefined,
          file: file || undefined,
          thumb: thumb || undefined,
          owner: owner || undefined,
        },
        options
      )
      .then((result) => result.data);
  }

  // Get a specific artifact
  // @param id - ID of artifact to retrieve
  static async getArtifact(id: string): Promise<Artifact> {
    return axiosPrivate
      .get<unknown, AxiosResponse<Artifact, unknown>, unknown>(`artifacts/${id}`)
      .then((response) => response.data);
  }

  // Update the given data on the specified artifact
  // @param id - ID of artifact to update
  // @param description - Description of the artifact
  // @param externalLink - URI for an externally hosted artifact (e.g. YouTube video)
  // @param tags - Array of tags associated with this artifact
  // @param file - URI from file-picker for the artifact file
  // @param thumb - URI from file-picker for the artifact thumbnail
  // @param owner - Owner of the artifact (only settable by Admins)
  static async updateArtifact(
    id: string,
    description?: string,
    externalLink?: string,
    tags?: string[],
    file?: File,
    thumb?: File,
    owner?: string
  ): Promise<Artifact> {
    return axiosPrivate
      .patch<Artifact, AxiosResponse<Artifact, unknown>, unknown>(`artifacts/${id}`, {
        description: description || undefined,
        externalLink: externalLink || undefined,
        tags: tags || undefined,
        file: file || undefined,
        thumb: thumb || undefined,
        owner: owner || undefined,
      })
      .then((response) => response.data);
  }

  // Delete the specified artifact
  // @param id - ID of artifact to delete
  static deleteArtifact(id: string): Promise<void> {
    return axiosPrivate.delete(`artifacts/${id}`);
  }
}

export default ArtifactService;
