import React, { useState, useEffect } from 'react';
import Form from '../../../components/Form';
import FormService, { Form as FormType } from '../../../services/form.service';
import { logger } from '../../../lib/logger';

import '../../NewTicket/newTicket.css';

export default function IntakeForms() {
  const [forms, setForms] = useState<FormType[]>([]);
  const [info, setInfo] = useState<string>('Loading...');

  useEffect(() => {
    const getRemoteForms = async () => {
      await FormService.getRemoteForms()
        .then((forms) => {
          setInfo('');
          setForms(forms);
        })
        .catch((err) => logger.error(err));
    };

    void getRemoteForms();
  }, []);

  const handleAccept = () => {
    setInfo('Syncing Forms');
    FormService.syncRemoteForms()
      .then((value) => {
        setInfo("Forms Sync'd Successfully");
        setForms(value);
      })
      .catch((err) => {
        logger.error(err);
        if (err instanceof Error) {
          setInfo('Failed to Sync Forms: ' + err.toString());
        } else {
          setInfo('Failed to Sync Forms: unknown error');
        }
      });
  };

  return (
    <section className='flex flex-col items-center justify-start flex-1 mx-2 mt-4 text-center w-full'>
      <div className='flex-col w-full p-4 mb-4 '>
        <div className='flex justify-between items-center w-full'>
          <h2 className='text-xl font-semibold'>Intake Forms - Preview</h2>
          <button
            type='button'
            className='bg-red-600 hover:bg-red-700 btn btn-wide'
            onClick={() => {
              handleAccept();
            }}
          >
            Accept & Import
          </button>
        </div>
        <h3 className='mb-3'>{info}</h3>
        {forms
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((form) => {
            return (
              <div key={form.name}>
                <div className='divider w-[80%] mx-auto'></div>
                <h2 className='mb-4 text-2xl font-semibold'>{form.name}</h2>
                <Form form={form} name={'Preview'} />
              </div>
            );
          })}
      </div>
    </section>
  );
}
