import { axiosPrivate, ResultList } from './api.service';
import { Artifact } from './artifact.service';
import { Ticket } from './ticket.service';
import { User } from './user.service';

export interface Message {
  id: string;
  createdAt: Date | string;
  updatedAt: Date | string;
  sender: User;
  ticket: string;
  message?: string;
  artifacts?: Artifact[];
}
export interface Room {
  ticket: Ticket;
  latestMessage?: Message;
  unread: number;
  lastRead: { user: string; message: string }[];
  users: User[];
}

export interface UnreadResponse {
  count: number;
}
class ChatService {
  static createMessage(ticket: string, message?: string, artifacts?: File[]): Promise<Message> {
    const options = { headers: {} };
    if (artifacts) {
      options.headers = {
        'Content-Type': 'multipart/form-data',
      };
    }

    return axiosPrivate
      .post(
        `chat/${ticket}/messages/`,
        {
          message: message || undefined,
          artifacts: artifacts || undefined,
        },
        options
      )
      .then((response) => response.data as Message);
  }

  static updateMessage(ticket: string, id: string, message?: string, artifacts?: File[]): Promise<Message> {
    const options = { headers: {} };
    if (artifacts) {
      options.headers = {
        'Content-Type': 'multipart/form-data',
      };
    }

    return axiosPrivate
      .patch(
        `chat/${ticket}/messages/${id}/`,
        {
          message: message || undefined,
          artifacts: artifacts || undefined,
        },
        options
      )
      .then((response) => response.data as Message);
  }

  static deleteMessage(ticket: string, id: string): Promise<void> {
    return axiosPrivate.delete(`chat/${ticket}/messages/${id}/`);
  }

  static getMessages(
    ticket: string,
    before?: string,
    limit?: number,
    page?: number,
    sortField?: string,
    sortOrder = 'asc'
  ): Promise<ResultList<Message>> {
    return axiosPrivate
      .get(`chat/${ticket}`, {
        params: {
          before: before || undefined,
          sortBy: sortField ? `${sortField}:${sortOrder}` : undefined,
          limit: limit || undefined,
          page: page || undefined,
        },
      })
      .then((response) => response.data as ResultList<Message>);
  }

  static getMessage(ticket: string, id: string): Promise<Message> {
    return axiosPrivate.get(`chat/${ticket}/messages/${id}/`).then((response) => response.data as Message);
  }

  static getRooms(
    before?: string,
    limit?: number,
    page?: number,
    sortField?: string,
    sortOrder = 'asc'
  ): Promise<ResultList<Room>> {
    return axiosPrivate
      .get(`chat/`, {
        params: {
          before: before || undefined,
          sortBy: sortField ? `${sortField}:${sortOrder}` : undefined,
          limit: limit || undefined,
          page: page || undefined,
        },
      })
      .then((response) => response.data as ResultList<Room>);
  }

  static getRoom(ticket: string): Promise<Message> {
    return axiosPrivate.get(`chat/${ticket}`).then((response) => response.data as Message);
  }

  static updateLastReadMessage(ticket: string, id: string): Promise<void> {
    return axiosPrivate.patch(`chat/${ticket}/lastRead`, {
      params: {
        messageId: id,
      },
    });
  }

  static getUnreadMessageCount(): Promise<UnreadResponse> {
    return axiosPrivate.get(`chat/unread`, {}).then((res) => res.data as UnreadResponse);
  }
}

export default ChatService;
