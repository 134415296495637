import { axiosPrivate, ResultList } from './api.service';
import { Artifact } from './artifact.service';
import { Token } from './auth.service';

export interface VideoRoom {
  ticket: string;
  ticketName: string;
}

class VideoService {
  // Get list of Meetings
  // @param name - Name of the Meeting to use as a filter
  // @param sortField - Field to sort results by
  // @param sortOrder - Order of sorting (only if sortField supplied)
  // @limit - Maximum number of results per page
  // @page - Page of results to return
  static getRooms(
    name?: string,
    limit?: string,
    page?: string,
    sortField?: string,
    sortOrder = 'asc'
  ): Promise<ResultList<VideoRoom>> {
    return axiosPrivate
      .get('video/rooms', {
        params: {
          name: name || undefined,
          sortBy: sortField ? `${sortField}:${sortOrder}` : undefined,
          limit: limit || undefined,
          page: page || undefined,
        },
      })
      .then((response) => response.data as Promise<ResultList<VideoRoom>>);
  }

  // Get a specific Meeting
  // @param id - ID of Meeting to retrieve
  static joinMeeting(id: string): Promise<Token> {
    return axiosPrivate.post(`video/rooms/${id}`).then((response) => response.data as Promise<Token>);
  }

  // Get list of videos
  // @param name - Last name of the video to use as a filter
  // @param tags - Video tags to use as a filter
  // @param sortField - Field to sort results by
  // @param sortOrder - Order of sorting (only if sortField supplied)
  // @limit - Maximum number of results per page
  // @page - Page of results to return
  static getVideos(
    name?: string,
    tags?: string[],
    limit?: number,
    page?: number,
    sortField?: string,
    sortOrder = 'asc'
  ): Promise<ResultList<Artifact>> {
    return axiosPrivate
      .get('video/library', {
        params: {
          tags: tags || undefined,
          name: name || undefined,
          sortBy: sortField ? `${sortField}:${sortOrder}` : undefined,
          limit: limit || undefined,
          page: page || undefined,
        },
      })
      .then((response) => response.data as ResultList<Artifact>);
  }
}

export default VideoService;
