import React from 'react';
// import { useNavigate } from 'react-router-dom';

const Unauthorized = () => {
  // const navigate = useNavigate();

  // const goBack = () => navigate(-1);

  return (
    <section>
      <h1>Subscription Expired</h1>
      <br />
      <p>You do not have an active subscription to TheAVApp.</p>
      <p>
        Visit <a href='https://theavapp.com'>TheAVApp.com</a> to renew your subscription.
      </p>
      {/* <div className='flexGrow'>
        <button onClick={goBack}>Go Back</button>
      </div> */}
    </section>
  );
};

export default Unauthorized;
