import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import TaskService from '../../services/task.service';
import ChatService from '../../services/chat.service';
import useAuth from '../../hooks/useAuth';
import { logger } from '../../lib/logger';

const Home = () => {
  const [tasks, setTasks] = useState([]);
  const user = useAuth()?.user;
  const pendingTasks = tasks.filter((task) => task.stage === 'created');
  const [unreadMessages, setUnreadMessages] = useState(0);

  useEffect(() => {
    const getTasks = async () => {
      await TaskService.getTasks()
        .then((taskPages) => {
          taskPages.results.sort((a, b) => a.createdAt - b.createdAt).reverse();
          setTasks(taskPages.results);
        })
        .catch((err) => logger.error(err));
    };

    getTasks();
  }, []);

  useEffect(() => {
    ChatService.getUnreadMessageCount().then((count) => setUnreadMessages(count.count));
  }, []);

  return (
    <section className='flex flex-col items-center justify-around min-h-[calc(100vh-68px)] py-12'>
      <div className='mx-4 mb-4 text-center join join-vertical lg:join-horizontal'>
        <p className='px-4 py-2 uppercase border-2 border-solid rounded-lg rounded-l-lg border-slate-700 join-item'>
          Next Task
        </p>
        <p
          style={!pendingTasks[0] ? { color: '#93d45b' } : null}
          className='px-4 py-2 uppercase border-2 border-solid rounded-lg border-slate-700 join-item'
        >
          {pendingTasks[0] ? pendingTasks[0].name : 'No Tasks Found'}
        </p>
      </div>
      <main className='flex flex-col items-center justify-center gap-8'>
        {['tech', 'admin'].includes(user.role) ? (
          <Link className='btn btn-wide btn-secondary' to='/admin'>
            Admin Page
          </Link>
        ) : (
          <Link className='btn btn-wide btn-secondary' to='/newproject'>
            Start A New Project
          </Link>
        )}
        <div className='indicator'>
          {pendingTasks.length !== 0 && <span className='indicator-item badge badge-info'>{pendingTasks.length}</span>}
          <Link className='btn btn-wide btn-secondary' to='/tasklist'>
            My Task List
          </Link>
        </div>
        <div className='indicator'>
          {unreadMessages !== 0 && <span className='indicator-item badge badge-info'>{unreadMessages}</span>}
          <Link className='btn btn-wide btn-secondary' to='/chat'>
            Go To Chat
          </Link>
        </div>
        <Link className='btn btn-wide btn-secondary' to='/meeting'>
          Join My Meeting
        </Link>
        <Link className='btn btn-wide btn-secondary' to='/videolibrary'>
          Video Library
        </Link>
      </main>
      {/* <p className='px-10 py-2 mx-4 mb-8 text-xs uppercase border-2 border-solid rounded-lg border-slate-700'>
        download shortcut to your phone
      </p> */}
    </section>
  );
};

export default Home;
