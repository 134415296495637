import { axiosPrivate, ResultList } from './api.service';
import { Artifact } from './artifact.service';

export interface Field {
  id: string;
  label: string;
  type: 'file' | 'files' | 'text' | 'checkbox' | 'radio' | 'select' | 'multiple';
  scale?: { min: number; max: number; titles: string[] };
  options?: string[];
  placeholder?: string;
  conditionals?: Conditional[];
  defaultValue?: FormResponseData;
}

export interface Conditional {
  id: string;
  placeholder: string;
  match?: string | boolean | number;
  defaultValue?: string;
}

export interface Category {
  title: string;
  fields: Field[];
}

export interface Form {
  name: string;
  submitted: boolean;
  categories: Category[];
}

export type FormData = string | string[] | boolean | number | File[] | undefined;
export type FormResponseData = string | string[] | boolean | number | Artifact[];

export interface FormResponse {
  id: string;
  user: string;
  formName: string;
  ticket: string;
  responses: Record<string, FormResponseData>;
}

class FormService {
  // Get a specific form
  // @param id - Name of the form to retrieve
  static async getForms(
    ticket: string,
    name?: string,
    limit?: number,
    page?: number,
    sortField?: string,
    sortOrder = 'asc'
  ): Promise<ResultList<Form>> {
    return axiosPrivate
      .get(`forms`, {
        params: {
          ticket,
          name: name || undefined,
          sortBy: sortField ? `${sortField}:${sortOrder}` : undefined,
          limit: limit || undefined,
          page: page || undefined,
        },
      })
      .then((response) => response.data as ResultList<Form>);
  }

  // Get a specific form
  // @param name - Name of the form to retrieve
  // @param ticket - ID of a saved ticket to fill form with
  static getForm(name: string, ticket?: string): Promise<Form> {
    return axiosPrivate.get(`forms/${name}`, { params: { ticket } }).then((response) => response.data as Form);
  }

  // Get remote (source) forms
  static getRemoteForms(): Promise<Form[]> {
    return axiosPrivate.get(`forms/sync`).then((response) => response.data as Form[]);
  }

  // Sync remote (source) forms
  static syncRemoteForms(): Promise<Form[]> {
    return axiosPrivate.post(`forms/sync`).then((response) => response.data as Form[]);
  }
}

export default FormService;
