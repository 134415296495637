import React, { useState, useEffect } from 'react';
// import { UpCircleFilled } from '@ant-design/icons';
import { FaChevronUp } from 'react-icons/fa6';

import './ScrollToTop.css';

const ScrollToTop = () => {
  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 500) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className='relative'>
      {showTopBtn && <FaChevronUp className='fixed z-20 p-2 bottom-6 right-6 icon-style' onClick={goToTop} />}
    </div>
  );
};

export default ScrollToTop;
