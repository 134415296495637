/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useEffect, useState } from 'react';
import Meeting from './Meeting';
import VideoService, { VideoRoom } from '../../services/video.service';
import { logger } from '../../lib/logger';

const MeetingRoomList = () => {
  const [room, setRoom] = useState<VideoRoom | undefined>();
  const [token, setToken] = useState<string | undefined>();
  const [rooms, setRooms] = useState<VideoRoom[]>([]);
  const [selectedMeeting, setSelectedRoom] = useState<number>(-1);
  const [showHeader, setShowHeader] = useState<boolean>(true);

  useEffect(() => {
    VideoService.getRooms(undefined, undefined, undefined, 'createdAt', 'desc')
      .then((roomResult) => {
        logger.log('roomResults.results', roomResult.results);
        setRooms(roomResult.results);
      })
      .catch((error) => {
        logger.error(error);
      });
  }, []);

  const joinRoom = () => {
    if (room) {
      VideoService.joinMeeting(room.ticket)
        .then((meetingToken) => {
          setToken(meetingToken.token);
          setShowHeader(false);
        })
        .catch((err) => {
          logger.error('Failed to join meeting', err);
        });
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const index = parseInt(event.target.value, 10);
    setSelectedRoom(index);
    if (index >= 0) {
      setRoom(rooms[index]);
    } else {
      setRoom(undefined);
    }
  };

  return (
    <div className='flex flex-col items-center h-[calc(100vh-68px)] '>
      {showHeader && (
        <>
          <h1 className='pt-5 text-lg'>Video Chat</h1>
          <div className='divider w-[80%] self-center mb-12'></div>
        </>
      )}
      {room && token ? (
        <Meeting ticketId={room.ticket} meetingToken={token} />
      ) : (
        <div className='w-full mb-8 form-control max-w-[95%] md:max-w-[60%]'>
          <select
            className='w-full select select-bordered bg-[#000000] max-w-6xl'
            value={selectedMeeting}
            onChange={handleChange}
          >
            <option key='selectRoom' value={-1}>
              Select a meeting
            </option>
            {rooms?.map((room, index) => (
              <option style={{ margin: '2rem 0' }} key={room.ticket} value={index}>
                {room.ticketName}
              </option>
            ))}
          </select>
          <button onClick={joinRoom} className='mt-8 w-[80%] btn btn-success self-center' disabled={!room}>
            Join
          </button>
        </div>
      )}
    </div>
  );
};

export default MeetingRoomList;
