import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { API_URL } from '../../services/api.service';
import userService from '../../services/user.service';

const Login = () => {
  const user = useAuth()?.user;
  const setUser = useAuth()?.setUser;

  const navigate = useNavigate();
  const location = useLocation();
  interface FromPath {
    from?: {
      pathname: string;
    };
  }
  const from = ((location.state as FromPath)?.from?.pathname as string) || '/';

  // const errRef = useRef<HTMLParagraphElement>(null);
  // const [errMsg, setErrMsg] = useState('');

  useEffect(() => {
    if (setUser) {
      setUser(userService.oAuthCallback(window));
    }
  }, [setUser]);

  useEffect(() => {
    if (user) {
      navigate(from, { replace: true });
    }
  }, [navigate, from, user]);

  return (
    <div className='h-[calc(100vh-67px)] flex-center'>
      <section className='section flex-center'>
        {/* <p ref={errRef} className={errMsg ? 'errmsg' : 'offscreen'} aria-live='assertive'>
          {errMsg}
        </p> */}
        {/* <h1 className='mb-6 text-2xl'>Sign In</h1> */}
        <div>
          <button
            className='hover:bg-transparent hover:border-[#cdced0] w-full max-w-xs btn btn-ghost border-[#858585]'
            onClick={() => userService.oAuthLogin(window, `${API_URL}/auth/login/theavapp`)}
          >
            Sign In with TheAVApp
          </button>
        </div>
      </section>
    </div>
  );
};

export default Login;
