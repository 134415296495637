import React, { useState, useEffect } from 'react';
import { logger } from '../../../lib/logger';
import TaskService, { TaskMeta } from '../../../services/task.service';

import '../../TaskList/taskList.css';

export default function DefaultTasks() {
  const [tasks, setTasks] = useState<TaskMeta[]>([]);
  const [info, setInfo] = useState<string>('Loading...');

  useEffect(() => {
    const getDefaultTasks = async () => {
      await TaskService.getRemoteTasks()
        .then((tasks) => {
          setInfo('');
          setTasks(tasks);
        })
        .catch((err) => logger.error(err));
    };

    void getDefaultTasks();
  }, []);

  const handleAccept = () => {
    setInfo('Syncing Tasks');
    TaskService.syncRemoteTasks()
      .then((value) => {
        setInfo("Tasks Sync'd Successfully");
        setTasks(value);
      })
      .catch((err) => {
        logger.error(err);
        if (err instanceof Error) {
          setInfo('Failed to Sync Tasks: ' + err.toString());
        } else {
          setInfo('Failed to Sync Tasks: unknown error');
        }
      });
  };

  return (
    <section className='flex flex-col items-center justify-start flex-1 mx-2 mt-4 text-center w-full'>
      <div className='flex-col w-full p-4 mb-4 '>
        <div className='flex justify-between items-center w-full'>
          <h2 className='text-xl font-semibold'>Default Tasks - Preview</h2>
          <button
            type='button'
            className='bg-red-600 hover:bg-red-700 btn btn-wide'
            onClick={() => {
              handleAccept();
            }}
          >
            Accept & Import
          </button>
        </div>
        <h3 className='mb-3'>{info}</h3>
        {tasks && tasks.length > 0 && (
          <div className='overflow-x-auto table-zebra border-2 border-[#cdced0a9] rounded bg-neutral'>
            <table className='table'>
              <thead className='bg-base-100'>
                <tr>
                  <th>Name</th>
                  <th>Description</th>
                  <th className=''>Allow Files</th>
                </tr>
              </thead>
              <tbody>
                {tasks?.map((task, index) => {
                  return (
                    <tr key={`${index}`}>
                      <td className='pl-1 pr-1'>
                        <div className='flex items-center space-x-3'>
                          <div>
                            <div className='font-bold'>{task.name}</div>
                          </div>
                        </div>
                      </td>
                      <td className='line-clamp-3 h-[5.4em]'>{task.description}</td>

                      <td className='px-0 pl-3'>{task.allowFiles ? 'Yes' : 'No'}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </section>
  );
}
