/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import TaskService from '../services/task.service';
import TicketService from '../services/ticket.service';
import { PlusOutlined, EyeOutlined } from '@ant-design/icons';
import { logger } from '../lib/logger';
import { ArtifactLink } from './ArtifactLink';

const TechTables = ({ user }) => {
  const [tasks, setTasks] = useState([]);
  const [activeTask, setActiveTask] = useState(null);
  const [tickets, setTickets] = useState([]);
  const [filteredTickets, setFilteredTickets] = useState([]);
  const [data, setData] = useState({
    taskName: '',
    taskDescription: '',
    additionalFileRadio: '',
  });
  const { ...otherProps } = data;
  const canSave = [...Object.values(otherProps)].every(Boolean);

  const handleSubmit = async (ticket) => {
    await TaskService.createTask(data.taskName, data.taskDescription, ticket.id, data.additionalFileRadio).then((res) => {
      logger.log('created task response', res);
      setData({
        taskName: '',
        taskDescription: '',
        additionalFileRadio: '',
      });
      setTasks([...tasks, res]);
    });
  };

  const handleChange = (e) => {
    const type = e.target.type;

    const name = e.target.name;

    const value = type === 'checkbox' ? e.target.checked : e.target.value;
    // const value = e.target.value;

    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  useEffect(() => {
    const getTickets = async () => {
      await TicketService.getTickets()
        .then((ticketPages) => {
          ticketPages.results.sort((a, b) => a.createdAt - b.createdAt).reverse();
          setTickets(ticketPages.results);
        })
        .catch((err) => logger.error(err));
    };
    const getTasks = async () => {
      await TaskService.getTasks()
        .then((taskPages) => {
          taskPages.results.sort((a, b) => (a.stage < b.stage ? 1 : -1));
          setTasks(taskPages.results);
        })
        .catch((err) => logger.error(err));
    };

    getTasks();
    getTickets();
  }, []);

  useEffect(() => {
    const ticketResults = tickets.map((ticket) => {
      if (user.role === 'admin') {
        return { id: ticket.id, name: ticket.name, tasks: tasks.filter((task) => task.ticket === ticket.id) };
      }
      if (user.id === ticket.assigned) {
        return { id: ticket.id, name: ticket.name, tasks: tasks.filter((task) => task.ticket === ticket.id) };
      }
      return undefined;
    });
    const filtered = ticketResults.filter((ticket) => ticket !== undefined);
    setFilteredTickets(filtered);
  }, [user.id, user.role, tickets, tasks]);

  const techTables = filteredTickets.map((ticket) => {
    return (
      <div className='mb-12 place-items-center flex flex-col w-[95%] sm:w-[95%] md:w-[80%] lg:w-[65%]' key={ticket.id}>
        <div className='my-12 divider'></div>
        <div className='flex flex-col w-full'>
          <div className='flex items-center justify-between mb-2'>
            <div className='flex flex-col'>
              <h2 className=''>{ticket.name}</h2>
            </div>
            <div className=''>
              <button
                onClick={() => {
                  document.getElementById(`${ticket.id}_modal`).showModal();
                }}
                className='btn btn-sm btn-success'
              >
                <PlusOutlined />
              </button>
              <dialog
                data-item={ticket}
                id={`${ticket.id}_modal`}
                className='modal modal-top sm:modal-middle text-stone-200'
              >
                <form
                  method='dialog'
                  className='modal-box max-w-[95%] rounded-t-none justify-self-center rounded border border-[#E5E9F0] border-t-0 sm:border-b'
                >
                  <h3 className='text-lg font-bold'>Enter new task for project: {` ${ticket.name}`}</h3>
                  <div className='w-full max-w-xs form-control'>
                    <label htmlFor='taskName' className='label'>
                      <span className='label-text'>*Task Name</span>
                    </label>
                    <input
                      onChange={handleChange}
                      name='taskName'
                      id='taskName'
                      type='text'
                      value={data.taskName}
                      placeholder='Task Name'
                      className='w-full max-w-xs inset-field input input-bordered bg-slate-200 text-[#333333]'
                      required
                    />
                  </div>
                  <div className='form-control'>
                    <label htmlFor='taskDescription' className='label'>
                      <span className='label-text'>*Task Description</span>
                    </label>
                    <textarea
                      onChange={handleChange}
                      name='taskDescription'
                      id='taskDescription'
                      value={data.taskDescription}
                      className='inset-field  h-24 textarea textarea-bordered bg-slate-200 text-[#333333] leading-6 text-[1rem]'
                      placeholder='Project Description...'
                      required
                    ></textarea>
                  </div>
                  <div>
                    <div className='mt-10 text-center form-control'>
                      <h3>*Does this task require additional files from the client?</h3>
                      <div className='flex justify-around'>
                        <label htmlFor='radioYes' className='justify-start cursor-pointer label'>
                          <span className='w-12 label-text'>YES</span>
                          <input
                            onChange={handleChange}
                            id='radioYes'
                            type='radio'
                            name='additionalFileRadio'
                            value={true}
                            className='radio checked:bg-[#a3be8c]'
                            required
                          />
                        </label>
                        <label htmlFor='radioNo' className='justify-start cursor-pointer label'>
                          <span className='w-12 label-text'>NO</span>
                          <input
                            onChange={handleChange}
                            id='radioNo'
                            type='radio'
                            name='additionalFileRadio'
                            value={false}
                            className='radio  checked:bg-[#bf616a]'
                            required
                          />
                        </label>
                      </div>
                    </div>
                    <div className='form-control'></div>
                  </div>
                  <div className='modal-action'>
                    {/* closes the modal */}
                    <button
                      type='submit'
                      className='btn btn-info'
                      disabled={!canSave}
                      onClick={(e) => {
                        handleSubmit(ticket);
                      }}
                    >
                      Add Task
                    </button>
                  </div>
                </form>
                <form method='dialog' className='bg-black opacity-80 modal-backdrop '>
                  <button className='w-[100vw]'>close</button>
                </form>
              </dialog>
            </div>
          </div>
          <div className='overflow-x-auto table-zebra border-2 border-[#cdced0a9] rounded bg-neutral'>
            <table className='table'>
              <thead className='bg-base-100'>
                <tr>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Files</th>
                  <th className='pl-1 '>Stage</th>
                  <th className='px-0'>View</th>
                </tr>
              </thead>
              {/* {ticket.tasks.sort((a, b) => (a.stage > b.stage ? 1 : -1))} */}
              <tbody>
                {ticket.tasks?.map((task) => {
                  return (
                    <tr style={task.stage === 'completed' ? { color: '#a3be8c' } : null} key={`${task?.id}`}>
                      <td className='w-16 px-1 font-bold'>{task.name}</td>
                      <td className='line-clamp-3 h-[5.4em] pr-1'>{task.description}</td>
                      <td>{task.allowFiles.toString()}</td>
                      <td className='px-1'>{task.stage === 'completed' ? 'done' : task.stage}</td>
                      <td className='px-0 pr-1'>
                        <EyeOutlined
                          className='pt-2 border-gray-400 rounded-lg btn-sm btn-square hover:bg-slate-800'
                          onClick={() => {
                            setActiveTask(task);
                            document.getElementById('info-modal').showModal();
                          }}
                        />
                        <dialog id='info-modal' className='modal text-stone-200'>
                          <form method='dialog' className=' modal-box w-[95%] '>
                            <h3 className='self-center mb-6 text-lg font-bold'>{activeTask?.name}</h3>
                            <div className='max-h-[450px] rounded overflow-scroll inset-field bg-slate-100 text-gray-800'>
                              <p className='px-3 pt-4'>{activeTask?.description}</p>
                            </div>
                            <p className='mt-6'>
                              Additional file attachments are {activeTask?.allowFiles ? '' : 'not'} required for this task.
                            </p>
                            <h3 className='mt-6'>Attachments: {activeTask?.artifacts.length > 0 ? null : 'NONE'}</h3>
                            {logger.log('activeTask.artifacts', activeTask?.artifacts)}
                            {activeTask?.artifacts && activeTask?.artifacts.length > 0 && (
                              <ul className='flex flex-col gap-4 mx-1 mt-6 overflow-auto text-base'>
                                {activeTask?.artifacts.map((artifact) => {
                                  logger.log('artifact.name', artifact.name);
                                  return (
                                    <li key={artifact} className='grid p-1 cursor-pointer place-items-center'>
                                      <ArtifactLink artifact={artifact} className='max-w-xs' preview={true} />
                                    </li>
                                  );
                                })}
                              </ul>
                            )}
                          </form>
                          <form method='dialog' className='modal-backdrop'>
                            <button className='w-[100vw] h-[100vh]'>close</button>
                          </form>
                        </dialog>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  });

  return techTables;
};

export default TechTables;
