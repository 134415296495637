import React, { useRef, useEffect, useState, useCallback } from 'react';
import useAuth from '../../hooks/useAuth';
import MessageForm from './MessageForm';
import MyMessage from './MyMessage';
import TheirMessage from './TheirMessage';
import { Message, Room } from '../../services/chat.service';
import IsTyping from './IsTyping';
import { User } from '../../services/user.service';
import Avatar from '../../components/Avatar';

import './Chat.css';

export interface MessageFeedProps {
  messages?: Message[];
  room?: Room;
  typers?: User[];
  onTyping?: (ticket: string) => void;
  onRead?: (ticket: string, message: string) => void;
  online?: string[];
}

export function MessageFeed({ room, messages, onTyping, typers, onRead, online }: MessageFeedProps) {
  const user = useAuth()?.user;
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const lastMessageRef = useRef<HTMLDivElement>(null);
  const [visibilityTimer, setVisibilityTimer] = useState<number | null>(null);

  const renderReadReceipts = useCallback(
    (message: Message, isMyMessage: boolean) =>
      room?.lastRead
        .filter((lastRead) => lastRead.user !== user?.id)
        .map((person) => {
          const roomUser = room.users.find((user) => user.id === person.user);
          if (!roomUser) return <div />;
          return (
            person.message === message.id && (
              <div
                key={`read_${roomUser.id}`}
                className='read-receipt'
                style={{
                  float: isMyMessage ? 'right' : 'left',
                }}
              >
                <Avatar {...roomUser} />
              </div>
            )
          );
        }),
    [user?.id, room?.users, room?.lastRead]
  );
  const handleMessageVisible = useCallback(
    (messageId: string) => {
      const lastReadMessageIdForCurrentUser = room?.lastRead.find((lastRead) => lastRead.user === user?.id)?.message;
      if (room && messageId !== lastReadMessageIdForCurrentUser) {
        onRead && onRead(room?.ticket.id, messageId);
      }
    },
    [room, onRead, user]
  );

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        startVisibilityTimer();
      } else {
        if (visibilityTimer) {
          clearTimeout(visibilityTimer);
          setVisibilityTimer(null);
        }
      }
    });

    const startVisibilityTimer = () => {
      const timer = window.setTimeout(() => {
        const lastMessage = messages && messages[messages.length - 1];
        if (lastMessage) {
          handleMessageVisible(lastMessage.id);
        }
      }, 1000);
      setVisibilityTimer(timer);
    };

    const localLastMessage = lastMessageRef.current;

    if (localLastMessage) {
      observer.observe(localLastMessage);

      // Check immediate visibility
      const rect = localLastMessage.getBoundingClientRect();
      const isVisible =
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth);

      if (isVisible) {
        startVisibilityTimer();
      }
    }

    return () => {
      if (localLastMessage) {
        observer.unobserve(localLastMessage);
      }
      if (visibilityTimer) {
        clearTimeout(visibilityTimer);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages, handleMessageVisible]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'instant' });
  }, [messages?.length]);

  return !room || messages === undefined ? (
    <div className='h-[calc(100vh-68px)] flex-center'>
      <span className='loading loading-dots loading-lg '></span>
    </div>
  ) : (
    <div className='chat-feed'>
      <div className='chat-title-container'>
        <div className='chat-title'>{room?.ticket.name}</div>
        <div className='chat-subtitle'>{room?.users?.map((person) => `${person.displayName}`).join(' • ')}</div>
      </div>
      <div id='messageContainer'>
        {messages?.map((message, index) => {
          const isLastMessage = index === messages.length - 1;
          const isMyMessage = user?.id === message.sender.id;
          return (
            <div key={`msg_${message.id}`} style={{ width: '100%' }} ref={isLastMessage ? lastMessageRef : null}>
              <div className='message-block'>
                {isMyMessage ? (
                  <MyMessage message={message} />
                ) : (
                  <TheirMessage
                    message={message}
                    previousMessage={messages ? messages[index - 1] : undefined}
                    online={online || []}
                  />
                )}
              </div>
              <div
                className='read-receipts'
                style={{ marginRight: isMyMessage ? '18px' : '0px', marginLeft: isMyMessage ? '0px' : '68px' }}
              >
                {renderReadReceipts(message, isMyMessage)}
              </div>
            </div>
          );
        })}
      </div>
      <div style={{ height: '10px' }} ref={messagesEndRef} />
      <div className='message-form-container'>
        <IsTyping typers={typers} />
        <MessageForm
          onChange={() => {
            onTyping && onTyping(room.ticket.id);
          }}
          ticket={room.ticket.id}
        />
      </div>
    </div>
  );
}

export default MessageFeed;
