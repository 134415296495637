import React, { useState } from 'react';
import { SendOutlined, UploadOutlined } from '@ant-design/icons';
import ChatService from '../../services/chat.service';
import { logger } from '../../lib/logger';

import './Chat.css';

interface MessageFormProps {
  ticket: string;
  onChange?: () => void;
}

const MessageForm = ({ ticket, onChange }: MessageFormProps) => {
  const [value, setValue] = useState<string>('');
  const [attachedFiles, setAttachedFiles] = useState<File[]>([]);

  const handleChange: React.ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    setValue(event.target.value);
    onChange && onChange();
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    const text = value.trim();
    if (text.length > 0 || attachedFiles.length > 0) {
      ChatService.createMessage(ticket, text, attachedFiles)
        .then(() => {
          setValue('');
          setAttachedFiles([]);
        })
        .catch((err: Error) => {
          logger.error('Failed sending message', err);
        });
    }
  };

  const handleUpload: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    logger.log('event', event.target);
    // if (event.target.files) {
    //   setAttachedFiles([...attachedFiles, ...Array.from(event.target.files)]);
    // }
  };

  return (
    <div className='message-form-container'>
      <form className='message-form' onSubmit={handleSubmit}>
        <textarea
          className='w-full rounded-lg message-input inset-field'
          placeholder='Send a message...'
          value={value}
          onChange={handleChange}
        />
        <button
          className='h-full btn btn-outline'
          style={{ backgroundColor: '#bf616a', width: '50px' }}
          onClick={handleUpload}
        >
          <input type='file' multiple={false} id='upload-button' style={{ display: 'none' }} /*onChange={handleUpload}*/ />
          <label htmlFor='upload-button'>
            <span className='image-button'>
              <UploadOutlined className='pb-1' aria-label='Upload file' />
            </span>
          </label>
        </button>
        <button type='submit' className='w-16 h-full btn btn-outline btn-info'>
          <SendOutlined />
        </button>
      </form>
    </div>
  );
};

export default MessageForm;
