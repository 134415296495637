import React from 'react';
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

const RequireAuth = () => {
  const user = useAuth()?.user;
  const location = useLocation();

  if (!user) {
    return <Navigate to='/login' state={{ from: location }} replace />;
  } else if (['user', 'admin', 'tech'].includes(user.role)) {
    return <Outlet />;
  } else {
    return <Navigate to='/unauthorized' state={{ from: '' }} replace />;
  }
};

export default RequireAuth;
