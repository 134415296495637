import React, { createContext, useState } from 'react';
import UserService, { User } from '../services/user.service';

interface AuthContextValue {
  user: User | undefined;
  setUser: React.Dispatch<React.SetStateAction<User | undefined>>;
}

interface Container {
  children: React.ReactNode;
}

const AuthContext = createContext<AuthContextValue | undefined>(undefined);

export const AuthProvider = (props: Container) => {
  const [user, setUser] = useState<User | undefined>(UserService.getCurrentUser());
  return <AuthContext.Provider value={{ user, setUser }}>{props.children}</AuthContext.Provider>;
};

export default AuthContext;
