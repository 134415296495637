import React, { useState, useEffect } from 'react';
import ScrollToTop from '../../components/ScrollToTop';
import './videoLibrary.css';
import VideoService from '../../services/video.service';
import { logger } from '../../lib/logger';

const VideoLibrary = () => {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    VideoService.getVideos()
      .then((videoPages) => {
        setVideos(videoPages.results);
      })
      .catch((err) => logger.error(err));
  }, []);

  // ! CONVERTED TO FLATMAP TO REMOVE THE PRIVATE VIDS FROM THE MAP FOR NOW -- WILL NEED TO BE REVERTED TO MAP
  const videosArray = videos.flatMap((video) => {
    if (video.name === 'Private video') {
      return [];
    }
    return (
      <div key={video.id} className='shadow-xl card card-compact w-80 bg-base-200'>
        <figure className='max-h-44'>
          <a href={video.externalLink} className='w-auto'>
            <img src={video.thumb.path} alt={video.name} />
          </a>
        </figure>
        <div className='card-body'>
          <h2 className='card-title'>{video.name}</h2>
          <p className='line-clamp-3 max-h-14'>{video.description}</p>
        </div>
      </div>
    );
  });

  return (
    <div className='flex flex-col my-6 flex-center'>
      <ScrollToTop />
      <h1 className='pt-5 text-lg'>Helpful Videos</h1>
      <div className='divider w-[80%] self-center'></div>
      {videosArray && <div className='video-container'>{videosArray}</div>}
    </div>
  );
};

export default VideoLibrary;
