import React from 'react';

export interface AvatarProps {
  firstName?: string;
  lastName?: string;
  role?: string;
  displayName?: string;
  online?: boolean;
}

export const getColor = (role?: string): string => {
  switch (role) {
    case 'admin':
      return '#5e81ac';
    case 'tech':
      return '#bf616a';
    case 'user':
      return '#a3be8c';
    default:
      return '#222222';
  }
};

function Avatar({ firstName, lastName, role, displayName, online }: AvatarProps) {
  const getText = (): string => {
    if (firstName && lastName) {
      return `${firstName.slice(0, 1)}${lastName.slice(0, 1)}`.toUpperCase();
    }
    if (displayName) {
      return displayName.slice(0, 2).toUpperCase();
    }
    if (role) {
      return role[0].toUpperCase();
    }
    return '?';
  };

  return (
    <div className={`avatar placeholder ${online !== undefined ? (online ? 'online' : 'offline') : ''}`}>
      {firstName && lastName && (
        <div
          className='w-10 text-lg text-center border-gray-700 rounded-full'
          style={{
            backgroundColor: getColor(role),
            borderWidth: firstName ? '2px' : '0px',
          }}
        >
          <div className='text-gray-700'>{getText()}</div>
        </div>
      )}
    </div>
  );
}

export default Avatar;
