import React, { CSSProperties, useEffect, useState, useCallback } from 'react';
import RoomCard from './RoomCard';
import ChatService, { Room } from '../../services/chat.service';
import { logger } from '../../lib/logger';
import OnVisible from './OnVisible';

interface RoomListProps {
  rooms?: Room[];
  setRooms: (rooms: Room[] | undefined) => void;
  activeRoom?: Room;
  setActiveRoom: (room: Room | undefined) => void;
}

const RoomList = ({ rooms, setRooms, activeRoom, setActiveRoom }: RoomListProps) => {
  const [lastRoom, setLastRoom] = useState<string | undefined>(undefined);

  function sortRooms(chats: Room[]) {
    return chats.sort((a, b) => {
      const aDate = a.latestMessage?.createdAt || a.ticket.createdAt;
      const bDate = b.latestMessage?.createdAt || b.ticket.createdAt;
      return new Date(bDate).getTime() - new Date(aDate).getTime();
    });
  }

  const loadRooms = useCallback(() => {
    ChatService.getRooms(lastRoom, 10, undefined, 'createdAt', 'desc')
      .then((results) => {
        const newRoomsMap: Record<string, Room> = {};
        results.results.forEach((room) => {
          newRoomsMap[room.ticket.id] = room;
        });
        const updatedRooms = (rooms || []).filter((room) => !newRoomsMap[room.ticket.id]);
        setRooms(sortRooms([...updatedRooms, ...results.results]));
        setLastRoom(results.page < results.totalPages ? results.results[results.results.length - 1]?.ticket.id : undefined);
      })
      .catch((err: Error) => {
        logger.error('Failed getting rooms', err);
      });
  }, [rooms, lastRoom, setRooms]);

  useEffect(() => {
    ChatService.getRooms(undefined, 10, undefined, 'createdAt', 'desc')
      .then((results) => {
        setRooms(sortRooms(results.results));
        setActiveRoom(results.results[0]);
        setLastRoom(results.page < results.totalPages ? results.results[results.results.length - 1]?.ticket.id : undefined);
      })
      .catch((err: Error) => {
        logger.error('Failed getting rooms', err);
      });
  }, [setActiveRoom, setRooms]);

  return (
    <div style={styles.chatListContainer} className='ce-chat-list'>
      <div style={styles.chatsContainer} className='ce-chats-container'>
        {rooms?.map((room) => {
          return (
            <div key={`chat_${room.ticket.id}`}>
              <RoomCard
                room={room}
                isActive={room.ticket.id === activeRoom?.ticket.id}
                onClick={() => setActiveRoom(room)}
              />
            </div>
          );
        })}
        {lastRoom && (
          <div>
            <OnVisible onVisible={loadRooms} />
            <div style={{ height: '8px' }} />
          </div>
        )}
      </div>
    </div>
  );
};

const styles: Record<string, CSSProperties> = {
  chatListContainer: {
    height: '100%',
    maxHeight: '100vh',
    overflow: 'scroll',
    overflowX: 'hidden',
    borderRight: '1px solid #afafaf',
    backgroundColor: 'white',
    fontFamily: 'Avenir',
  },
  chatsContainer: {
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    borderRadius: '0px 0px 24px 24px',
  },
};

export default RoomList;
