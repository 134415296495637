import React from 'react';
import useAuth from '../../hooks/useAuth';
import { User } from '../../services/user.service';
import { getColor } from '../../components/Avatar';

interface IsTypingProps {
  typers?: User[];
}
const IsTyping = ({ typers }: IsTypingProps) => {
  const user = useAuth()?.user;

  return (
    <div>
      {typers
        ?.filter((typer) => typer.id !== user?.id)
        .map((typer) => {
          return (
            <span
              key={`typer_${typer.id}`}
              className='ce-user-typing-text'
              style={{ color: getColor(typer?.role), padding: '2px', paddingLeft: '12px' }}
            >
              {`${typer.displayName} is typing...`}
            </span>
          );
        })}
    </div>
  );
};

export default IsTyping;
