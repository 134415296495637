import { axiosPrivate, ResultList } from './api.service';
import { FormData } from './form.service';

export interface Ticket {
  id: string;
  createdAt: Date;
  name: string;
  description: string;
  owner: string;
  stage: string;
  artifacts: string[];
  assigned?: string;
}

class TicketService {
  // Get list of tickets
  // @param name - Name of the ticket to use as a filter
  // @param stage - Stage of the ticket to use as a filter
  // @param sortField - Field to sort results by
  // @param sortOrder - Order of sorting (only if sortField supplied)
  // @limit - Maximum number of results per page
  // @page - Page of results to return
  static getTickets(
    name?: string,
    stage?: string,
    limit?: string,
    page?: string,
    sortField?: string,
    sortOrder = 'asc'
  ): Promise<ResultList<Ticket>> {
    return axiosPrivate
      .get('tickets', {
        params: {
          name: name || undefined,
          stage: stage || undefined,
          sortBy: sortField ? `${sortField}:${sortOrder}` : undefined,
          limit: limit || undefined,
          page: page || undefined,
        },
      })
      .then((response) => response.data as ResultList<Ticket>);
  }

  // Create a new Ticket with the given name and description
  // @param name - Name of the ticket
  // @param description - Description of the ticket
  // @param artifacts - List of artifact ids associated with this ticket
  // @param owner - Owner of the ticket (only settable by Admins)
  static createTicket(
    name: string,
    description?: string,
    owner?: string,
    forms?: Record<string, Record<string, FormData>>
  ): Promise<Ticket> {
    const options = { headers: {} };
    if (
      Object.values(forms || {}).some((form) =>
        Object.values(form).some(
          (value) => (Array.isArray(value) && value.some((item) => item instanceof File)) || value instanceof File
        )
      )
    ) {
      options.headers = {
        'Content-Type': 'multipart/form-data',
      };
    }
    return axiosPrivate
      .post(
        'tickets',
        {
          name,
          description: description || undefined,
          owner: owner || undefined,
          forms: forms || undefined,
        },
        options
      )
      .then((response) => response.data as Ticket);
  }

  // Get a specific ticket
  // @param id - ID of ticket to retrieve
  static getTicket(id: string): Promise<Ticket> {
    return axiosPrivate.get(`tickets/${id}`).then((response) => response.data as Ticket);
  }

  // Update the given data on the specified ticket
  // @param id - ID of ticket to update
  // @param name - Name to set on the ticket
  // @param description - Description to set on the ticket
  // @param stage - Stage to set on the ticket
  static updateTicket(id: string, name?: string, description?: string, stage?: string, assigned?: string): Promise<Ticket> {
    return axiosPrivate
      .patch(`tickets/${id}`, {
        name: name || undefined,
        description: description || undefined,
        stage: stage || undefined,
        assigned: assigned || undefined,
      })
      .then((response) => response.data as Ticket);
  }

  // Delete the specified ticket
  // @param id - ID of ticket to delete
  static deleteTicket(id: string): Promise<void> {
    return axiosPrivate.delete(`tickets/${id}`);
  }
}

export default TicketService;
