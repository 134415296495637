/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import UserService from '../../../services/user.service';
import TicketService from '../../../services/ticket.service';
import { ArtifactLink } from '../../../components/ArtifactLink';
import useAuth from '../../../hooks/useAuth';
import './Modal.css';
import { logger } from '../../../lib/logger';

const Modal = ({ id, ticket, updateTicket, onCancel }) => {
  const [techs, setTechs] = useState([]);
  const [clients, setClients] = useState([]);
  const [assigned, setAssigned] = useState('');
  const ref = useRef(null);
  const canSave = true;
  const user = useAuth()?.user;
  const [ticketChanges, setTicketChanges] = useState({
    assigned: null,
    name: null,
  });

  useEffect(() => {
    if (user.role !== 'admin') {
      return;
    }
    const getUsers = async () => {
      await UserService.getUsers()
        .then((users) => {
          let filteredTechs = [];
          let filteredClients = [];
          users.results.forEach((user) => {
            user.role === 'tech' && filteredTechs.push(user);
            user.role === 'user' && filteredClients.push(user);
          });
          setTechs(filteredTechs);
          setClients(filteredClients);
        })
        .catch((err) => logger.error('err: ', err));
    };
    getUsers();
  }, [user.role]);

  useEffect(() => {
    if (user.role === 'tech') {
      setAssigned(user.displayName);
      return;
    }
    if (ticketChanges.assigned) {
      setAssigned(techs.filter((tech) => tech.id === ticketChanges.assigned)[0]?.displayName);
      return;
    }
    if (ticket?.assigned) {
      setAssigned(techs.filter((tech) => tech.id === ticket.assigned)[0]?.displayName);
      return;
    }
    setAssigned('NONE');
    return;
  }, [user.role, user.displayName, ticket, techs, ticketChanges.assigned]);

  const handleSubmit = async (ticket) => {
    await TicketService.updateTicket(
      ticket.id,
      ticketChanges.name || ticket.name,
      null,
      ticket.stage,
      ticketChanges.assigned
    ).then((res) => {
      logger.log('res', res);
      if (updateTicket) {
        updateTicket(res);
      }
      setTicketChanges({ name: null, assigned: null });
    });
  };

  const handleKeyPress = (e) => {
    // if (e.keyCode == 13)
    if (e.key === 'Enter') {
      e.preventDefault();
      e.target.blur();
    }
  };

  //* ADD THE COLOR TO THE SELECTED NAME (E.TARGET.INNERHTML)
  //* LOOP THROUGH THE OTHER OPTIONS AND REMOVE THE COLOR FROM THEM
  const handleTechReassign = async (e, id) => {
    setTicketChanges({ ...ticketChanges, assigned: id === ticketChanges.assigned ? null : id });
  };

  const handleChange = (e) => {
    e.preventDefault();
    setTicketChanges({ ...ticketChanges, name: e.target.value === ticket.name ? null : e.target.value });
  };

  const handleCancel = () => {
    setTicketChanges({ name: null, assigned: null });
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <dialog id={id} className='text-sm modal modal-top sm:modal-middle text-stone-200' onCancel={handleCancel}>
      <form
        onKeyDown={(e) => {
          handleKeyPress(e);
        }}
        onSubmit={(e) => e.preventDefault()}
        method='dialog'
        className='modal-box md:min-w-[50%] px-2 max-w-[95%] justify-self-center rounded sm:rounded border rounded-t-none border-[#E5E9F0]  border-t-0 sm:border-b'
      >
        <div
          className={`flex flex-col items-start mb-4 ${ticket?.stage === 'created' ? 'text-[#a3be8c]' : 'text-[#bf616a]'}`}
        >
          <h3 className='text-xl font-bold'>{`${ticket?.name}`}</h3>
          <div className='font-bold text-l'>
            {user.role === 'admin'
              ? clients.map((client) => (client.id === ticket?.owner ? client.displayName : null))
              : null}
          </div>
        </div>
        <div className='overflow-auto min-h-[40vh] h-[70vh] bg-[#3B4252] pt-2 rounded-lg px-2 md:py-5 md:px-6 flex flex-col'>
          <div className='justify-center w-full form-control'>
            <label className='label'>
              <span className='label-text'>Project Name</span>
            </label>
            <input
              // autoFocus={false}
              id='name-change-input'
              ref={ref}
              onChange={(e) => handleChange(e)}
              type='text'
              name='name'
              placeholder='Project Name'
              className={`text-[#333333] w-full inset-field input input-bordered ${
                ticketChanges.name && ticketChanges.name !== ticket?.name ? 'bg-[#bf616a]' : 'bg-[#E5E9F0]'
              }`}
              value={ticketChanges.name || ticket?.name || ''}
              readOnly={user.role === 'admin' ? false : true}
            />
          </div>
          <div className='mt-4'>
            <div>Project Description </div>
            <div className=' mt-2 min-h-10 overflow-auto px-2 pb-1 pt-2 text-[#333333] rounded-md inset-field bg-[#E5E9F0]'>
              {ticket?.description}
            </div>
          </div>
          <div className='flex flex-col items-start mt-6'>
            <div>
              Assigned Technician:{' '}
              <span
                className={`${
                  ticketChanges.assigned && ticketChanges.assigned !== ticket?.assigned ? 'text-red-500' : null
                }`}
              >
                {assigned}
              </span>
            </div>
            {user.role === 'admin' && (
              <div className='mt-2 rounded-lg min-h-[3rem] collapse collapse-arrow bg-info'>
                <input type='checkbox' />
                <div className='text-lg font-medium collapse-title'>Re-assign Technician</div>
                <ul id='tech-list' className='overflow-auto collapse-content max-h-32 scroll-smooth bg-base-200'>
                  {techs.map((tech) => (
                    <li
                      onClick={(e) => {
                        handleTechReassign(e, tech.id, ticket);
                      }}
                      className={`${
                        ticketChanges.assigned &&
                        ticket?.assigned !== ticketChanges.assigned &&
                        tech.id === ticketChanges.assigned
                          ? 'text-red-500'
                          : null
                      } py-1 pl-1 mt-1 rounded-sm cursor-pointer hover:bg-slate-600`}
                      key={tech.id}
                    >
                      {tech.displayName}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className='mt-6 '>
            <h2>Attached Files: {ticket?.artifacts.length} </h2>
            <div className='my-2 px-1 pt-1 overflow-auto border rounded inset-field bg-[#E5E9F0] text-gray-900'>
              {ticket?.artifacts.length > 0 ? (
                <div className='artifact-container'>
                  {logger.log('ticket.artifacts', ticket.artifacts)}
                  {ticket?.artifacts.map((item) => (
                    <div key={item}>
                      <ArtifactLink artifact={item} className='' preview={true} />
                    </div>
                  ))}
                </div>
              ) : (
                <p>None</p>
              )}
            </div>
          </div>

          <div></div>
        </div>
        {user.role === 'admin' && (
          <div className='flex justify-between modal-action'>
            <button
              onClick={(e) => {
                handleCancel();
                document.getElementById(id).close();
              }}
              className='btn btn-error'
            >
              Cancel
            </button>
            <button
              className='btn btn-info'
              disabled={!canSave}
              onClick={(e) => {
                handleSubmit(ticket);
                document.getElementById(id).close();
              }}
            >
              Submit
            </button>
          </div>
        )}
      </form>
      <form method='dialog' className='bg-black opacity-80 modal-backdrop '>
        <button className='w-[100vw]'>close</button>
      </form>
    </dialog>
  );
};

export default Modal;
