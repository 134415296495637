import { io, Socket } from 'socket.io-client';
import { logger } from '../lib/logger';
import { Room, Message } from './chat.service';
import { DisconnectDescription } from 'socket.io-client/build/esm/socket';
import { axiosPrivate } from './api.service';
import AuthService from './auth.service';

export interface ChatSocketProps {
  onError?: (error: Error) => void;
  onRoom?: (room: Room) => void;
  onConnected?: () => void;
  onMessages?: (messages: Message[]) => void;
  onDeleteMessage?: (ticket: string, message: string) => void;
  onTyping?: (ticket: string, user: string) => void;
  onUserOnline?: (ticket: string, user: string, online: boolean) => void;
  onUserReadMessage?: (ticket: string, user: string, message: string) => void;
  onDisconnected?: () => void;
}

export class ChatSocket {
  private socket?: Socket;
  private props: ChatSocketProps;

  constructor(props: ChatSocketProps) {
    this.props = props;
    this.init();
  }

  private handleConnect = () => {
    this.props.onConnected?.();
  };

  private handleDisconnect = (reason: Socket.DisconnectReason, description?: DisconnectDescription) => {
    logger.log(`Socket Disconnected`, reason, description);
    this.props.onDisconnected?.();
  };

  private handleError = (e: Error) => {
    this.props.onError?.(e);
    logger.error('Socket Error', e);
  };

  private handleMessages = (messages: Message[]) => {
    this.props.onMessages?.(messages);
  };

  private handleMessage = (message: Message) => {
    this.handleMessages([message]);
  };

  private handleDeleteMessage = ({ ticket, message }: { ticket: string; message: string }) => {
    this.props.onDeleteMessage?.(ticket, message);
  };

  private handleRoom = (room: Room) => {
    this.props.onRoom && this.props.onRoom(room);
  };

  private handleOnline = ({ ticket, users }: { ticket: string; users: { user: string; online: boolean }[] }) => {
    users.forEach((user) => this.props.onUserOnline?.(ticket, user.user, user.online));
  };

  private handleTyping = ({ ticket, user }: { ticket: string; user: string }) => {
    this.props.onTyping?.(ticket, user);
  };

  private handleMessageRead = ({ ticket, user, message }: { ticket: string; user: string; message: string }) => {
    this.props.onUserReadMessage?.(ticket, user, message);
  };

  init() {
    const socketURL = new URL(axiosPrivate.getUri().replace('http', 'ws'), window.location.toString());
    socketURL.pathname = `${socketURL.pathname}/chat/socket`.replace('//', '/');
    const localSocket = io(socketURL.origin, {
      auth: {
        token: `Bearer ${AuthService.getAccessToken()?.token || ''}`,
      },
      path: socketURL.pathname,
    });

    localSocket.on('connect', this.handleConnect);
    localSocket.on('disconnect', this.handleDisconnect);
    localSocket.on('error', this.handleError);

    localSocket.on('message', this.handleMessage);
    localSocket.on('messages', this.handleMessages);
    localSocket.on('delete_message', this.handleDeleteMessage);
    localSocket.on('read_message', this.handleMessageRead);

    localSocket.on('chat', this.handleRoom);

    localSocket.on('online', this.handleOnline);
    localSocket.on('typing', this.handleTyping);

    this.socket = localSocket;
  }

  join(rooms: string[]) {
    this.socket && this.socket.emit('join', rooms);
  }

  disconnect() {
    this.socket && this.socket.disconnect();
  }

  typing(ticket: string) {
    this.socket && this.socket.emit('typing', { ticket });
  }

  readMessage(ticket: string, message: string) {
    this.socket && this.socket.emit('read_message', { ticketId: ticket, messageId: message });
  }
}
