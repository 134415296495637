import React, { useEffect, useRef } from 'react';
import DailyIframe, { DailyCall } from '@daily-co/daily-js';
import { logger } from '../../lib/logger';
import useAuth from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';

const CALL_OPTIONS = {
  iframeStyle: {
    width: '100%',
    height: '100%',
    border: '1px solid #e6eaef',
    borderRadius: '6px 6px 0 0',
  },
  showLeaveButton: true,
  showFullscreenButton: true,
  //   showLocalVideo: false,
  //   showParticipantsBar: false,
};

interface VideoCallProps {
  ticketId: string;
  meetingToken: string;
}

const VideoCallComponent: React.FC<VideoCallProps> = ({ ticketId, meetingToken }) => {
  const user = useAuth()?.user;
  const videoRef = useRef<HTMLDivElement>(null);
  const callFrameRef = useRef<DailyCall | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!videoRef.current || !ticketId || !meetingToken) return;
    if (!callFrameRef.current) {
      callFrameRef.current = DailyIframe.createFrame(videoRef.current, {
        ...CALL_OPTIONS,
        userName: user?.displayName,
      });
    }

    callFrameRef.current
      .join({
        url: `https://theavapp.daily.co/${ticketId}`,
        token: meetingToken,
        theme: {
          colors: {
            accent: '#ff0000',
            baseText: '#ffffff',
            accentText: '#1d232a',
            background: '#121a24',
            backgroundAccent: '#1d232a',
            mainAreaBg: '#1d232a',
            mainAreaBgAccent: '#353c4a',
          },
        },
      })
      .catch((err) => {
        logger.error('Error joining video room: ', err);
      });

    // If there's an existing callFrame, leave the call before creating a new one
    // Return'd function gets called on any dep change or unmounting of component
    return () => {
      if (callFrameRef.current) {
        callFrameRef.current
          .destroy()
          .then(() => {
            callFrameRef.current = null;
            navigate('/');
          })
          .catch((err) => {
            logger.error('Error destroying video room: ', err);
          });
      }
    };
  }, [meetingToken, ticketId, user?.displayName, navigate]);

  return <div ref={videoRef} className='w-full h-full' />;
};

export default VideoCallComponent;
