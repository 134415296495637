import React, { useEffect, useState, useRef } from 'react';
import { TrashIcon } from '@heroicons/react/24/outline';
import fileFilter from '../lib/fileFilter';
import { Field, FormData } from '../services/form.service';
import { Artifact } from '../services/artifact.service';
import { ArtifactLink } from './ArtifactLink';

export interface FormFieldProps {
  field: Field;
  onFieldChange?: (id: string, value: FormData) => void;
  formData?: Record<string, FormData>;
  readOnly: boolean;
}

function FormField({ field, formData, onFieldChange, readOnly }: FormFieldProps) {
  const [visibleConditionals, setVisibleConditionals] = useState<Record<string, boolean>>({});
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (multiple: boolean, event: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles = Array.from(event.target.files || []);
    const fileMap = new Map<string, File>();

    if (multiple) {
      selectedFiles.forEach((file) => fileMap.set(file.name, file));
    }

    newFiles.forEach((file) => fileMap.set(file.name, file));

    const uniqueFiles = Array.from(fileMap.values());
    setSelectedFiles(uniqueFiles);
    if (onFieldChange) {
      onFieldChange(field.id, uniqueFiles);
    }

    if (multiple && fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const removeFile = (fileToRemove: File) => {
    const updatedFiles = selectedFiles.filter((file) => file !== fileToRemove);
    setSelectedFiles(updatedFiles);
    if (onFieldChange) {
      onFieldChange(field.id, updatedFiles);
    }

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleMultiSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const values = Array.from(event.target.selectedOptions).map((option) => option.value);
    if (onFieldChange) {
      onFieldChange(field.id, values);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    if (onFieldChange) {
      onFieldChange(field.id, event.target.value);
    }
  };

  const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onFieldChange) {
      onFieldChange(field.id, event.target.checked);
    }
  };

  useEffect(() => {
    const visibility: Record<string, boolean> = {};
    field.conditionals?.forEach((conditional) => {
      if (conditional.match === undefined || conditional.match === null) {
        visibility[conditional.id] = true;
        return;
      }

      if (formData) {
        const formDataValue = formData[field.id];
        switch (field.type) {
          case 'checkbox':
            visibility[conditional.id] = Boolean(conditional.match) === Boolean(formDataValue);
            break;
          case 'radio':
            visibility[conditional.id] = String(conditional.match) === String(formDataValue);
            break;
          case 'multiple':
            const isAllStrings = (array: (string | File)[]): array is string[] => {
              return array.every((item) => typeof item === 'string');
            };
            visibility[conditional.id] =
              Array.isArray(formDataValue) &&
              isAllStrings(formDataValue) &&
              formDataValue.includes(String(conditional.match));
            break;
          case 'file':
          case 'files':
            visibility[conditional.id] = !!formDataValue;
            break;
          default:
            visibility[conditional.id] = String(conditional.match) === String(formDataValue);
        }
      } else if (readOnly) {
        visibility[conditional.id] = true;
      }
    });

    setVisibleConditionals(visibility);
  }, [field, formData, readOnly]);

  const conditionalFields = field.conditionals?.map((conditional) => {
    const value = String(formData?.[conditional.id] ?? '');
    if (!visibleConditionals[conditional.id] || (readOnly && !value)) return null;
    return (
      <div key={conditional.id} className='w-full max-w-sm mt-2 form-control'>
        <input
          className={`w-full bg-[#fef2f2] text-zinc-${readOnly ? '600' : '800'} inset-field ${
            readOnly ? 'cursor-not-allowed' : ''
          } form-input input input-bordered max-w-sm`}
          type='text'
          id={conditional.id}
          placeholder={readOnly && formData ? '' : conditional.placeholder}
          value={value}
          onChange={(event) => (onFieldChange ? onFieldChange(conditional.id, event.target.value) : null)}
          readOnly={readOnly}
        />
      </div>
    );
  });

  switch (field.type) {
    case 'text':
      return (
        <div className='w-full max-w-sm text-left form-control'>
          <label htmlFor={field.id} className='font-semibold text-left label text-md '>
            <span className='label-text'>{field.label}</span>
          </label>
          <input
            className={`text-zinc-${readOnly ? '600' : '800'} ${
              readOnly ? 'cursor-not-allowed' : ''
            } w-full max-w-xs bg-[#fef2f2] inset-field input input-bordered`}
            type='text'
            id={field.id}
            value={(formData?.[field.id] as string) || ''}
            onChange={handleInputChange}
            readOnly={readOnly}
          />
          {conditionalFields}
        </div>
      );
    case 'checkbox':
      return (
        <div className='w-full max-w-sm form-control'>
          <label htmlFor={field.id} className='font-semibold cursor-pointer label text-md'>
            <span className='mr-2 label-text'>{field.label}</span>
            <input
              className={`checkbox bg-[#fef2f2] ${readOnly ? 'cursor-not-allowed' : ''}`}
              type='checkbox'
              id={field.id}
              checked={(formData?.[field.id] as boolean) || false}
              onChange={handleCheckChange}
              readOnly={readOnly}
            />
          </label>
          {conditionalFields}
        </div>
      );
    case 'radio':
      return (
        <div className='w-full max-w-sm pb-5 form-control '>
          {!!field.scale?.titles?.length && (
            <div className='flex justify-between mb-2'>
              {field.scale.titles.map((title, i) => (
                <div key={i} className='label-text'>
                  {title}
                </div>
              ))}
            </div>
          )}
          <span className='font-semibold text-left text-md label label-text'>{field.label}</span>
          <div className='flex max-w-full gap-4 justify-evenly'>
            {field.options
              ? field.options.map((option, i) => (
                  <label
                    key={i}
                    className={`cursor-pointer label ${
                      option.length > 5 && window.innerWidth < 500 ? 'flex flex-col-reverse justify-start gap-1' : ''
                    }`}
                    htmlFor={field.id + '-' + i.toString()}
                  >
                    <input
                      className='radio checked:bg-[#b6b8bd]'
                      type='radio'
                      name={field.id}
                      value={i}
                      id={field.id + '-' + i.toString()}
                      checked={formData?.[field.id] === `${i}` || field.defaultValue === i}
                      onChange={handleInputChange}
                      disabled={readOnly}
                    />
                    <span className='ml-1 label-text'>{option}</span>
                  </label>
                ))
              : Array.from({ length: (field.scale?.max || 5) - (field.scale?.min || 1) + 1 }).map((_, i) => (
                  <div key={i} className='flex items-center'>
                    <input
                      className='radio checked:bg-[#b6b8bd] '
                      type='radio'
                      id={field.id}
                      value={i}
                      checked={formData?.[field.id] === `${i}` || field.defaultValue === i}
                      onChange={handleInputChange}
                      disabled={readOnly}
                    />
                  </div>
                ))}
          </div>
          {conditionalFields}
        </div>
      );
    case 'select':
      return (
        <div className='w-full max-w-sm mb-8 form-control'>
          <label className='font-semibold label text-md'>
            <span className='label-text'>{field.label}</span>
          </label>
          <select
            className='select select-bordered bg-[#1d232a] max-w-72'
            id={field.id}
            value={((formData?.[field.id] || field.defaultValue) as string) || ''}
            onChange={handleInputChange}
            disabled={readOnly}
          >
            <option value='' disabled>
              {readOnly ? '' : 'Select an option'}
            </option>
            {field.options?.map((option, index) => (
              <option key={index} value={index}>
                {option}
              </option>
            ))}
          </select>

          {conditionalFields}
        </div>
      );
    case 'multiple':
      return (
        <div className='w-full max-w-sm pb-5 form-control'>
          <label htmlFor={field.id} className='font-semibold label text-md'>
            <span className='label-text'>{field.label}</span>
          </label>
          <select
            className='py-1 select select-bordered'
            id={field.id}
            value={((formData?.[field.id] || field.defaultValue) as string[]) || []}
            onChange={handleMultiSelectChange}
            multiple
            size={field.options?.length}
            disabled={readOnly}
          >
            {field.options?.map((option, index) => (
              <option key={index} value={index} className='p-1'>
                {option}
              </option>
            ))}
          </select>

          {conditionalFields}
        </div>
      );
    case 'file':
    case 'files':
      const multiple = field.type === 'files';
      return (
        <div className='w-full max-w-sm form-control '>
          <label htmlFor={field.id} className='font-semibold label text-md'>
            <span className='label-text'>{field.label}</span>
          </label>
          {readOnly ? (
            Array.isArray(field.defaultValue) ? (
              field.defaultValue.map((artifact, index) => (
                <ArtifactLink key={index} artifact={artifact as Artifact} className='cursor-pointer' />
              ))
            ) : (
              <ArtifactLink artifact={field.defaultValue as unknown as Artifact} className='cursor-pointer' />
            )
          ) : (
            <>
              <div
                className='bg-red-50 border border-gray-300 rounded-md cursor-pointer shadow-sm flex justify-between items-center'
                onClick={() => fileInputRef.current && fileInputRef.current.click()}
              >
                <span className='file-input font-semibold label label-text bg-gray-700 uppercase text-center px-4 py-3 rounded-r-none'>
                  Choose File{multiple ? 's' : ''}
                </span>
                <span className='bg-red-50 flex-1 text-left px-4'>
                  {selectedFiles.length === 0
                    ? `No file${multiple ? 's' : ''} chosen`
                    : multiple
                    ? `${selectedFiles.length} file${selectedFiles.length !== 1 ? 's' : ''}`
                    : `${selectedFiles[0].name}`}
                </span>
              </div>
              <input
                ref={fileInputRef}
                className='hidden'
                type='file'
                id={field.id}
                accept={fileFilter(field.options || ['any'])}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileChange(multiple, event)}
                readOnly={readOnly}
                multiple={multiple}
              />
              {multiple && (
                <div className='w-full max-w-sm'>
                  {selectedFiles.map((file, index) => (
                    <div className='w-full' key={index}>
                      <span className='max-w-[calc(100%-1.25rem)] float-left'>{file.name}</span>
                      <TrashIcon
                        onClick={() => removeFile(file)}
                        className='h-5 w-5 text-[#bf616a] hover:text-[#d64d4d] cursor-pointer float-right inline-flex'
                      />
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
          {conditionalFields}
        </div>
      );
    default:
      return null;
  }
}

export default FormField;
