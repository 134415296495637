import { axiosPrivate, ResultList } from './api.service';

export interface TaskMeta {
  name: string;
  description: string;
  allowFiles?: boolean;
}

export interface Task extends TaskMeta {
  id: string;
  ticket: string;
  stage: string;
  artifacts?: string[];
}

class TaskService {
  // Get list of tasks
  // @param name - Name of the task to use as a filter
  // @param stage - Stage of the task to use as a filter
  // @param ticket - TicketID to use as a filter
  // @param sortField - Field to sort results by
  // @param sortOrder - Order of sorting (only if sortField supplied)
  // @limit - Maximum number of results per page
  // @page - Page of results to return
  static getTasks(
    name?: string,
    stage?: string,
    ticket?: string,
    limit?: number,
    page?: number,
    sortField?: string,
    sortOrder = 'asc'
  ): Promise<ResultList<Task>> {
    return axiosPrivate
      .get('tasks', {
        params: {
          name: name || undefined,
          stage: stage || undefined,
          ticket: ticket || undefined,
          sortBy: sortField ? `${sortField}:${sortOrder}` : undefined,
          limit: limit || undefined,
          page: page || undefined,
        },
      })
      .then((response) => response.data as ResultList<Task>);
  }

  // Create a new Task with the given name and description
  // @param name - Name of the task
  // @param description - Description of the task
  // @param taskId - ID of the ticket this task is associated with
  // @param owner - Owner of the task (only settable by Admins)
  static createTask(name: string, description: string, ticketId: string, allowFiles?: boolean): Promise<Task> {
    return axiosPrivate
      .post('tasks', {
        name,
        description,
        ticket: ticketId,
        allowFiles: allowFiles !== null ? allowFiles : undefined,
      })
      .then((response) => response.data as Task);
  }

  // Get a specific task
  // @param id - ID of task to retrieve
  static getTask(id: string): Promise<Task> {
    return axiosPrivate.get(`tasks/${id}`).then((response) => response.data as Task);
  }

  // Update the given data on the specified task
  // @param id - ID of task to update
  // @param name - Name to set on the task
  // @param description - Description to set on the task
  // @param stage - Stage to set on the task
  // @param files - Files to include on this task (if allowed)
  static updateTask(id: string, name?: string, description?: string, stage?: string, artifacts?: File[]): Promise<Task> {
    const options = { headers: {} };
    if (artifacts) {
      options.headers = {
        'Content-Type': 'multipart/form-data',
      };
    }

    return axiosPrivate
      .patch(
        `tasks/${id}`,
        {
          name: name || undefined,
          description: description || undefined,
          stage: stage || undefined,
          artifacts: artifacts || undefined,
        },
        options
      )
      .then((response) => response.data as Task);
  }

  // Delete the specified task
  // @param id - ID of task to delete
  static deleteTask(id: string): Promise<void> {
    return axiosPrivate.delete(`tasks/${id}`);
  }

  // Get remote (source) default tasks
  static getRemoteTasks(): Promise<TaskMeta[]> {
    return axiosPrivate.get(`tasks/sync`).then((response) => response.data as TaskMeta[]);
  }

  // Sync remote (source) default tasks
  static syncRemoteTasks(): Promise<TaskMeta[]> {
    return axiosPrivate.post(`tasks/sync`).then((response) => response.data as TaskMeta[]);
  }
}

export default TaskService;
