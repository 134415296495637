import React, { CSSProperties } from 'react';
import { htmlToText } from 'html-to-text';
import { Room } from '../../services/chat.service';
import Loading from './Loading';

interface RoomCardProps {
  room: Room;
  isActive: boolean;
  onClick?: () => void;
}

const RoomCard = ({ room, isActive, onClick }: RoomCardProps) => {
  if (!room) return <Loading />;

  const divStyle: CSSProperties = { border: 'none', background: 'none', width: '100%', textAlign: 'left' };
  const extraStyle = isActive ? styles.activeChat : {};
  const title = room.ticket.name;

  let lastMessage = htmlToText(room?.latestMessage?.message?.substring(0, 20) || '', {});
  if (!lastMessage) {
    lastMessage =
      room.latestMessage && room.latestMessage.artifacts && room.latestMessage.artifacts.length > 0
        ? `${room.latestMessage.artifacts.length} attachment${room.latestMessage.artifacts.length > 1 ? 's' : ''}`
        : 'No Messages';
  }

  function didReadLastMessage(room: Room) {
    // TODO: Get last read message for current user+room
    return true;
  }

  function daySinceSent(date: Date | string) {
    if (!date) return '';
    date = new Date(date);
    const now = new Date();
    const isThisYear = date.getFullYear() === now.getFullYear();
    const isToday = date.getDate() === now.getDate() && date.getMonth() === now.getMonth() && isThisYear;

    if (isToday) {
      return date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
    } else {
      return date.toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: isThisYear ? undefined : 'numeric' });
    }
  }

  return (
    <button
      onClick={() => onClick && onClick()}
      style={{ ...styles.chatContainer, ...extraStyle, ...divStyle }}
      className={`ce-chat-card ${isActive ? 'ce-active-chat-card' : ''}`}
    >
      <div style={styles.titleText} className='ce-chat-title-text' id={`ce-chat-card-title-${title}`}>
        <div
          style={{
            width: !didReadLastMessage(room) ? 'calc(100% - 18px)' : undefined,
            overflowX: 'hidden',
            display: 'inline-block',
          }}
        >
          {title}
        </div>

        {!didReadLastMessage(room) && (
          <div
            className='ce-chat-unread-dot'
            style={{
              marginTop: '5px',
              width: '12px',
              height: '12px',
              borderRadius: '6px',
              backgroundColor: '#1890ff',
              float: 'right',
              display: 'inline-block',
            }}
          />
        )}
      </div>

      <div style={{ width: '100%' }} className='ce-chat-subtitle'>
        <div style={styles.messageText} className='ce-chat-subtitle-text ce-chat-subtitle-message'>
          {lastMessage}
        </div>

        <div
          className='ce-chat-subtitle-text ce-chat-subtitle-date'
          style={{ ...styles.messageText, ...{ textAlign: 'right', width: '5em' } }}
        >
          {room.latestMessage && daySinceSent(room.latestMessage.createdAt)}
        </div>
      </div>
    </button>
  );
};

const styles: Record<string, CSSProperties> = {
  chatContainer: {
    padding: '16px',
    paddingBottom: '12px',
    cursor: 'pointer',
  },
  titleText: {
    fontWeight: '500',
    paddingBottom: '4px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  messageText: {
    width: 'calc(100% - 5em)',
    color: 'rgba(153, 153, 153, 1)',
    fontSize: '14px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    display: 'inline-block',
  },
  activeRoom: {
    backgroundColor: '#d9d9d9',
    border: '0px solid white',
    borderRadius: '12px',
  },
};

export default RoomCard;
