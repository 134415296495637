import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Room, Message } from '../../services/chat.service';
import './Chat.css';

import { ChatSocket } from '../../services/chat.socket.service';

import RoomList from './RoomList';
import MessageFeed from './MessageFeed';

import { Row, Col } from 'react-grid-system';
import { setConfiguration } from 'react-grid-system';
import { User } from '../../services/user.service';

setConfiguration({ maxScreenClass: 'xl', gutterWidth: 0 });

const Chat = () => {
  const [socket, setSocket] = useState<ChatSocket>();
  const [activeRoom, setActiveRoom] = useState<Room>();
  const [typers, setTypers] = useState<Record<string, User[]>>({});
  const typingTimers = useRef<Record<string, NodeJS.Timeout>>({});
  const [online, setOnline] = useState<Record<string, string[]>>({});
  const [messages, setMessages] = useState<Record<string, Message[]>>({});
  const [rooms, setRooms] = useState<Room[]>();
  const [roomIds, setRoomIds] = useState<string[]>();

  const roomsRef = useRef<Room[]>();
  useEffect(() => {
    setMessages((prevMessages = {}) => {
      const updatedMessages = { ...prevMessages };
      rooms?.forEach((room) => {
        if (room.latestMessage === undefined) {
          updatedMessages[room.ticket.id] = [];
        }
      });
      return updatedMessages;
    });
    roomsRef.current = rooms;
    setMessages((prevMessages = {}) => {
      const updatedMessages = { ...prevMessages };
      rooms?.forEach((room) => {
        if (!room.latestMessage) {
          updatedMessages[room.ticket.id] = updatedMessages[room.ticket.id] || [];
        }
      });
      return updatedMessages;
    });
  }, [rooms]);

  const setInitialRooms = useCallback((rooms?: Room[]) => {
    setRooms(rooms);
    setRoomIds(rooms?.map((room) => room.ticket.id));
  }, []);

  const onTyping = (ticket: string) => {
    socket?.typing(ticket);
  };

  const onRead = (ticket: string, message: string) => {
    socket?.readMessage(ticket, message);
  };

  useEffect(() => {
    const currentTimers = typingTimers.current;
    const localSocket = new ChatSocket({
      onRoom: (newRoom: Room) => {
        setRooms((prevRooms = []) => {
          const existingRoomIndex = prevRooms.findIndex((r) => r.ticket.id === newRoom.ticket.id);
          if (existingRoomIndex !== -1) {
            const updatedRooms = [...prevRooms];
            updatedRooms[existingRoomIndex] = newRoom;
            return updatedRooms;
          } else {
            setRoomIds((prevRoomIds) => [...(prevRoomIds || []), newRoom.ticket.id]);
            return [...prevRooms, newRoom];
          }
        });
      },
      onMessages: (newMessages: Message[]) => {
        setMessages((prevMessages = {}) => {
          const updatedMessages = { ...prevMessages };
          newMessages.forEach((newMessage) => {
            if (!updatedMessages[newMessage.ticket]) {
              updatedMessages[newMessage.ticket] = [];
            }
            const existingMessageIndex = updatedMessages[newMessage.ticket].findIndex((msg) => msg.id === newMessage.id);
            if (existingMessageIndex !== -1) {
              updatedMessages[newMessage.ticket][existingMessageIndex] = newMessage;
            } else {
              updatedMessages[newMessage.ticket].push(newMessage);
            }
          });

          return updatedMessages;
        });

        setRooms((prevRooms) => {
          const updatedRooms = [...(prevRooms || [])];
          newMessages.forEach((newMessage) => {
            const roomIndex = updatedRooms.findIndex((room) => room.ticket.id === newMessage.ticket);
            if (roomIndex !== -1) {
              if (!updatedRooms[roomIndex].users.some((user) => user.id === newMessage.sender.id)) {
                updatedRooms[roomIndex].users.push(newMessage.sender);
              }
            }
          });
          return updatedRooms;
        });
      },
      onDeleteMessage: (ticket: string, messageId: string) => {
        setMessages((prevMessages) => {
          const updatedMessages = { ...prevMessages };
          updatedMessages[ticket] = updatedMessages[ticket].filter((msg) => msg.id !== messageId);
          return updatedMessages;
        });
      },
      onTyping: (ticket: string, userId: string) => {
        const room = roomsRef.current?.find((r) => r.ticket.id === ticket);
        const userObj = room?.users.find((u) => u.id === userId);
        if (!userObj) return;

        setTypers((prevTypers) => ({
          ...prevTypers,
          [ticket]: [...(prevTypers[ticket]?.filter((user) => user.id !== userId) || []), userObj],
        }));

        if (typingTimers.current[userId]) {
          clearTimeout(typingTimers.current[userId]);
        }

        typingTimers.current[userId] = setTimeout(() => {
          setTypers((prevTypers) => ({
            ...prevTypers,
            [ticket]: (prevTypers[ticket] || []).filter((u) => u.id !== userId),
          }));
        }, 2000);
      },
      onUserOnline: (ticket: string, user: string, online: boolean) => {
        setOnline((prevOnline) => {
          const currentOnlineUsers = prevOnline[ticket] || [];
          if (online) {
            if (!currentOnlineUsers.includes(user)) {
              return {
                ...prevOnline,
                [ticket]: [...currentOnlineUsers, user],
              };
            }
          } else {
            return {
              ...prevOnline,
              [ticket]: currentOnlineUsers.filter((u) => u !== user),
            };
          }

          return prevOnline;
        });
      },
      onUserReadMessage: (ticket: string, user: string, message: string) => {
        setRooms((prevRooms) => {
          const updatedRooms = [...(prevRooms || [])];
          const roomIndex = updatedRooms.findIndex((r) => r.ticket.id === ticket);

          if (roomIndex !== -1) {
            const userLastReadIndex = updatedRooms[roomIndex].lastRead.findIndex((entry) => entry.user === user);

            if (userLastReadIndex !== -1) {
              updatedRooms[roomIndex].lastRead[userLastReadIndex].message = message;
            } else {
              updatedRooms[roomIndex].lastRead.push({ user, message });
            }
          }

          return updatedRooms;
        });
      },
      // onDisconnected: () => {},
    });

    setSocket(localSocket);
    return () => {
      Object.values(currentTimers).forEach(clearTimeout);
      localSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (socket) {
      socket.join(roomIds || []);
    }
  }, [roomIds, socket]);

  return (
    <div className='ce-chat-engine' style={{ textAlign: 'left', backgroundColor: 'white' }}>
      <Row className='ce-wrapper'>
        <Col xs={0} sm={3} style={{ height: 'calc(100vh - 68px)' }} className='ce-chat-list-column'>
          <RoomList rooms={rooms} setRooms={setInitialRooms} activeRoom={activeRoom} setActiveRoom={setActiveRoom} />
        </Col>

        <Col xs={12} sm={6} style={{ height: 'calc(100vh - 68px)' }} className='ce-chat-feed-column'>
          {rooms !== undefined ? (
            <MessageFeed
              room={activeRoom}
              messages={activeRoom ? messages[activeRoom.ticket.id] : []}
              onTyping={onTyping}
              typers={activeRoom ? typers[activeRoom.ticket.id] : []}
              online={activeRoom ? online[activeRoom.ticket.id] : []}
              onRead={onRead}
            />
          ) : (
            <div className='h-[calc(100vh-68px)] flex-center'>
              <span>No Tickets</span>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Chat;
