import React, { useCallback, useState, useEffect } from 'react';
import Form from './Form';
import FormService, { FormData, Form as FormType } from '../services/form.service';
import { logger } from '../lib/logger';

export interface FormProps {
  name: string;
  ticket: string;
  submitText?: string;
  onSubmit?: (formName: string, values: { [k: string]: FormData }) => void;
}

const TicketForm: React.FC<FormProps> = ({ name, ticket, submitText, onSubmit }) => {
  const [form, setForm] = useState<FormType | null>(null);
  const [submitted, setSubmitted] = useState<boolean>(true);
  const [formData, setFormData] = useState<Record<string, FormData>>({});

  useEffect(() => {
    if (!name) {
      return;
    }
    FormService.getForm(name, ticket)
      .then((form) => {
        setForm(form);
        setSubmitted(form.submitted || false);
      })
      .catch((err: Error) => {
        logger.error('Failed to load form', err);
      });
  }, [ticket, name]);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (!name) {
      return;
    }
    if (onSubmit) {
      onSubmit(name, formData);
      setSubmitted(true);
    }
  };

  const handleFieldChange = useCallback((id: string, value: FormData) => {
    setFormData((prevState) => ({ ...prevState, [id]: value }));
  }, []);

  return (
    form && (
      <form onSubmit={handleSubmit} className='w-full max-w-3xl p-4 mx-auto bg-gray-700 rounded drop-shadow-lg'>
        <Form form={form} name={name} onFieldChange={handleFieldChange} formData={formData} />
        {/* // TODO: on submit/ onClick save data and send user to  correct projectType page (selected on NewTicket page) */}
        {!submitted && (
          <button type='submit' className='bg-red-600 hover:bg-red-700 btn'>
            {submitText || 'Submit'}
          </button>
        )}
      </form>
    )
  );
};

export default TicketForm;
