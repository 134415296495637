import React from 'react';
import { ArtifactLink } from '../../components/ArtifactLink';
import Avatar from '../../components/Avatar';
import { Message } from '../../services/chat.service';

export interface TheirMessageProps {
  previousMessage?: Message;
  message: Message;
  online: string[];
}
export function TheirMessage({ previousMessage, message, online }: TheirMessageProps) {
  const isFirstMessageByUser = !previousMessage || previousMessage.sender.id !== message.sender.id;

  return (
    <div className='message-row'>
      {isFirstMessageByUser && (
        <Avatar
          firstName={message.sender.firstName}
          lastName={message.sender.lastName}
          role={message.sender.role}
          displayName={message.sender.role}
          online={online && online.includes(message.sender.id)}
        />
      )}
      {message.artifacts &&
        message.artifacts.length > 0 &&
        message.artifacts?.map((artifact) => (
          <div key={`${message.id}:${artifact.id}`} className='img-container' style={{ float: 'left' }}>
            <ArtifactLink artifact={artifact} preview={true} className='message-image' />
          </div>
        ))}
      {message.message && (
        <div className='float-left mr-4 chat chat-start' style={{ marginLeft: isFirstMessageByUser ? '4px' : '48px,' }}>
          <div className='pt-2.5 max-w-sm mr-7 chat-bubble'>{message.message}</div>
        </div>
      )}
    </div>
  );
}

export default TheirMessage;
