import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from './pages/Login/Login';
import Layout from './components/Layout';
import Missing from './components/Missing';
import Unauthorized from './components/Unauthorized';
import Home from './pages/Home/Home';
import NewProject from './pages/NewTicket/NewTicket';
import TaskList from './pages/TaskList/TaskList';
import Chat from './pages/Chat/Chat';
import MeetingRoomList from './pages/Meeting/Meetings';
import VideoLibrary from './pages/VideoLibrary/VideoLibrary';
import Admin from './pages/Admin/Admin';
import UpdateForms from './pages/Admin/UpdateForms';
import Nav from './components/Nav';
import RequireAuth from './components/RequireAuth';

function App() {
  return (
    <div className='flex flex-col'>
      <Nav />
      <Routes>
        <Route path='/' element={<Layout />}>
          {/* public routes */}
          <Route path='login' element={<Login />} />
          <Route path='unauthorized' element={<Unauthorized />} />

          {/* we want to protect these routes */}
          <Route element={<RequireAuth />}>
            <Route path='/' element={<Home />} />
            <Route path='newproject' element={<NewProject />} />
            <Route path='tasklist' element={<TaskList />} />
            <Route path='chat' element={<Chat />} />
            <Route path='meeting' element={<MeetingRoomList />} />
            <Route path='videolibrary' element={<VideoLibrary />} />
            <Route path='admin' element={<Admin />} />
            <Route path='admin/forms' element={<UpdateForms />} />
          </Route>

          {/* catch all */}
          <Route path='*' element={<Missing />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
