import React, { useLayoutEffect, useState } from 'react';
import Form from '../../../components/TicketForm';
import '../../../pages/NewTicket/newTicket.css';
import FormService, { Form as FormModel } from '../../../services/form.service';
import { Ticket } from '../../../services/ticket.service';
import { logger } from '../../../lib/logger';

export interface FormProps {
  ticket: Ticket;
}

const ViewTicket: React.FC<FormProps> = ({ ticket }) => {
  const [forms, setForms] = useState<FormModel[]>([]);
  const [activeForm, setActiveForm] = useState('');
  const [form, setForm] = useState<React.ReactNode>();
  const ticketForm = 'ticketData';

  useLayoutEffect(() => {
    if (ticket.id) {
      FormService.getForms(ticket.id)
        .then((results) => {
          setForms(results.results);
          onTabClicked(ticketForm);
        })
        .catch((err) => {
          logger.error(err);
        });
    } else {
      setForms([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticket]);

  const onTabClicked = (name: string) => {
    if (name === activeForm) return;
    const nextForm = name === activeForm ? '' : name;
    if (nextForm === ticketForm) {
      setForm(ticketData);
    } else if (nextForm) {
      setForm(<Form name={nextForm} ticket={ticket.id} />);
    } else {
      setForm(null);
    }
    setActiveForm(nextForm);
  };

  const ticketData = (
    <form className='w-full max-w-3xl  xl:min-h-[450px] p-4 mx-auto bg-gray-700 rounded drop-shadow-lg place-items-center'>
      <div className='w-full form-control place-items-center'>
        <label className='label'>
          <span className='label-text'>Project Description</span>
        </label>
        <textarea
          name='description'
          placeholder='Project Description'
          className='w-full h-[396px] text-zinc-800 inset-field textarea textarea-bordered bg-red-50'
          value={ticket.description}
          readOnly={true}
        />
      </div>
    </form>
  );

  return (
    <div className='w-full p-4 mb-4 '>
      <div className='justify-center tabs'>
        <div
          key={`${ticket.id}:data`}
          className={`tab tab-lifted ${activeForm === ticketForm ? 'tab-active' : ''}`}
          onClick={() => onTabClicked(ticketForm)}
        >
          Details
        </div>
        {forms.map((form) => {
          return (
            <div
              key={`${ticket.id}:${form.name}`}
              className={`tab tab-lifted ${activeForm === form.name ? 'tab-active' : ''}`}
              onClick={() => onTabClicked(form.name)}
            >
              {form.name}
            </div>
          );
        })}
      </div>
      {form}
    </div>
  );
};

export default ViewTicket;
