import React from 'react';
import DefaultTasks from './components/DefaultTasks';
import IntakeForms from './components/IntakeForms';
import ScrollToTop from '../../components/ScrollToTop';

const Admin = () => {
  return (
    <div className='flex flex-col items-center my-6'>
      <ScrollToTop />

      <DefaultTasks />
      <div className='divider'></div>
      <IntakeForms />
    </div>
  );
};

export default Admin;
