import React, { useRef, useEffect } from 'react';

import { LoadingOutlined } from '@ant-design/icons';

interface OnVisibleProps {
  onVisible: () => void;
}

const OnVisible = ({ onVisible }: OnVisibleProps) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) return;
    const localRef = ref.current;
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        onVisible();
      }
    });
    observer.observe(localRef);

    return () => {
      if (localRef) {
        observer.unobserve(localRef);
      }
      observer.disconnect();
    };
  }, [onVisible, ref]);

  return (
    <div ref={ref} aria-busy='true' role='alert'>
      <div style={{ textAlign: 'center', backgroundColor: '#e2e2e2', margin: '4px', borderRadius: '4px' }}>
        <LoadingOutlined style={{ fontSize: '21px', padding: '24px' }} />
      </div>
    </div>
  );
};

export default OnVisible;
