import React, { useState, useEffect, useMemo } from 'react';
import { TrashIcon, ArrowUpTrayIcon } from '@heroicons/react/24/outline';
import { EyeOutlined } from '@ant-design/icons';
import TaskService from '../services/task.service';
import TicketService from '../services/ticket.service';
import { logger } from '../lib/logger';
import { ArtifactLink } from './ArtifactLink';

import './Tables.css';

const UserTables = () => {
  const [tasks, setTasks] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [activeTask, setActiveTask] = useState(null);
  const [filteredTickets, setFilteredTickets] = useState([]);

  const handleRowSubmit = async (e, task) => {
    if (task.allowFiles && task.artifacts.length === 0) {
      alert('File attachments are required to complete this task.  Please attach requested files.');
      return;
    }
    await TaskService.updateTask(task.id, null, null, (task.stage = 'completed'), task.artifacts).then((res) => {});
    e.target.disabled = true;
    try {
      document.getElementById(`${task.id}-btn2`).disabled = true;
    } catch {
      try {
        document.getElementById(`${task.id}-btn1`).disabled = true;
      } catch {
        return;
      }
    }
  };

  const handleUpload = (e, task) => {
    const newFilesArray = [...task.artifacts, ...Array.from(e.target.files)];
    const uniqueFilesArray = [...new Map(newFilesArray.map((file) => [file.name, file])).values()];
    setTasks(
      tasks.map((item) => {
        return item.id === task.id ? { ...item, artifacts: uniqueFilesArray } : item;
      })
    );
  };

  const handleDeleteUpload = (task, artifact) => {
    setTasks(
      tasks.map((item) => {
        return item.id === task.id
          ? { ...item, artifacts: item.artifacts.filter((file) => file.name !== artifact.name) }
          : item;
      })
    );
  };

  useMemo(() => {
    const getTickets = async () => {
      await TicketService.getTickets()
        .then((ticketPages) => {
          ticketPages.results.sort((a, b) => a.createdAt - b.createdAt).reverse();
          setTickets(ticketPages.results);
        })
        .catch((err) => logger.error(err));
    };

    getTickets();

    return () => {};
  }, []);

  useEffect(() => {
    const getTasks = async () => {
      await TaskService.getTasks()
        .then((taskPages) => {
          taskPages.results.sort((a, b) => (a.stage < b.stage ? 1 : -1));
          setTasks(taskPages.results);
        })
        .catch((err) => logger.error(err));
    };
    getTasks();
  }, []);

  useEffect(() => {
    const ticketResults = tickets.map((ticket) => {
      return { id: ticket.id, name: ticket.name, tasks: tasks.filter((task) => task.ticket === ticket.id) };
    });
    setFilteredTickets(ticketResults);
  }, [tickets, tasks]);

  const userTables = filteredTickets.map((ticket) => {
    return (
      <div className=' place-items-center flex flex-col max-w-[95%] md:w-[80%] lg:w-[65%]' key={ticket.id}>
        <div className='my-12 divider'></div>

        <div className='flex flex-col w-full'>
          <h2 className='mb-2'>{ticket.name}</h2>
          <div className='overflow-x-auto table-zebra border-2 border-[#cdced0a9] rounded bg-neutral'>
            <table className='table'>
              <thead className='bg-base-100'>
                <tr>
                  <th>Name</th>
                  <th>Description</th>
                  <th className='px-0'>View</th>
                  <th className=''>Files</th>
                  <th className='pl-3'>Submit</th>
                </tr>
              </thead>
              <tbody>
                {ticket.tasks?.map((task) => {
                  return (
                    <tr style={task.stage === 'completed' ? { color: '#a3be8c' } : null} id={task.id} key={`${task.id}`}>
                      <td className='pl-1 pr-1'>
                        <div className='flex items-center space-x-3'>
                          <div>
                            <div className='font-bold'>{task.name}</div>
                          </div>
                        </div>
                      </td>
                      <td className='line-clamp-3 h-[5.4em]'>{task.description}</td>

                      <td className='px-0'>
                        <EyeOutlined
                          className='pt-2 border-gray-400 rounded-lg btn-sm btn-square hover:bg-slate-800'
                          onClick={() => {
                            logger.log('task', task);
                            setActiveTask(task);
                            // getActiveArtifacts();
                            logger.log('activeTask', activeTask);
                            document.getElementById('info-modal').showModal();
                          }}
                        />
                        <dialog id='info-modal' className='modal text-stone-200'>
                          <form method='dialog' className='overflow-scroll modal-box'>
                            <h3 className='self-center mb-6 text-lg font-bold'>{activeTask?.name}</h3>
                            <div className='max-h-[450px] rounded overflow-scroll inset-field bg-slate-100 text-gray-800'>
                              <p className='px-3 pt-4'>{activeTask?.description}</p>
                            </div>
                            <p className='mt-6'>
                              Additional file attachments are {activeTask?.allowFiles ? '' : 'not'} required for this task.
                            </p>
                            {/* {logger.log('activeTask.allowFiles', activeTask.allowFiles)} */}
                            <h3 className='mt-6'>Attachments: {activeTask?.artifacts.length > 0 ? null : 'NONE'}</h3>

                            {logger.log('activeTask.artifacts', activeTask?.artifacts)}
                            {activeTask?.artifacts && activeTask?.artifacts.length > 0 && (
                              <ul className='flex flex-col gap-4 mx-1 mt-6 overflow-auto text-base'>
                                {activeTask?.artifacts.map((artifact) => {
                                  logger.log('artifact.name', artifact.name);
                                  return (
                                    <li key={artifact} className='grid p-1 cursor-pointer place-items-center'>
                                      <ArtifactLink artifact={artifact} className='max-w-xs' preview={true} />
                                    </li>
                                  );
                                })}
                              </ul>
                            )}
                          </form>
                          <form method='dialog' className='modal-backdrop'>
                            <button className='w-[100vw] h-[100vh]'>close</button>
                          </form>
                        </dialog>
                      </td>

                      <td className='px-0 pl-3'>
                        {task.allowFiles ? (
                          <div>
                            {task.artifacts.length ? (
                              <div className='indicator'>
                                <span className='w-3 h-4 text-xs indicator-item indicator-start badge badge-info'>
                                  {task.artifacts.length}
                                </span>
                                <button
                                  id={`${task.id}-btn1`}
                                  className='btn btn-sm btn-square bg-slate-700'
                                  onClick={() => {
                                    document.getElementById(`${task.id}_upload_modal`).showModal();
                                  }}
                                  disabled={task.stage === 'completed' ? true : false}
                                >
                                  <ArrowUpTrayIcon className='w-5 h-5 ' />
                                </button>
                              </div>
                            ) : (
                              <button
                                id={`${task.id}-btn2`}
                                className='btn btn-sm btn-square bg-slate-700'
                                onClick={() => {
                                  document.getElementById(`${task.id}_upload_modal`).showModal();
                                }}
                                disabled={task.stage === 'completed' ? true : false}
                              >
                                <ArrowUpTrayIcon className='w-5 h-5' />
                              </button>
                            )}
                            <dialog id={`${task.id}_upload_modal`} className='modal text-stone-200'>
                              <form method='dialog' className=' modal-box w-fit'>
                                <input
                                  onChange={(e) => {
                                    handleUpload(e, task, ticket);
                                  }}
                                  type='file'
                                  multiple
                                  required
                                  className='w-full max-w-xs file-input file-input-bordered'
                                />
                                {task.artifacts && task.artifacts.length > 0 && (
                                  <ul className='flex flex-col gap-4 mx-1 mt-6 text-base'>
                                    {task.artifacts.map((artifact) => {
                                      return (
                                        <li key={artifact.name} className='flex justify-between p-1 place-items-center'>
                                          {artifact.name}
                                          <TrashIcon
                                            onClick={() => {
                                              handleDeleteUpload(task, artifact);
                                            }}
                                            className='h-5 w-5 text-[#bf616a] hover:text-[#d64d4d] cursor-pointer'
                                          />
                                        </li>
                                      );
                                    })}
                                  </ul>
                                )}
                              </form>
                              <form method='dialog' className='bg-black opacity-80 modal-backdrop'>
                                <button className='w-[100vw]'>close</button>
                              </form>
                            </dialog>
                          </div>
                        ) : null}
                      </td>
                      <td className='pl-0 pr-2 '>
                        <button
                          onClick={(e) => {
                            handleRowSubmit(e, task);
                          }}
                          disabled={task.stage === 'completed' ? true : false}
                          className='task-submit-btn btn btn-sm btn-error hover:opacity-70'
                          title='Submit Task'
                        >
                          {task.stage === 'completed' ? 'Done' : 'Submit Task'}
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  });

  return userTables;
};

export default UserTables;
