import React from 'react';
import { ArtifactLink } from '../../components/ArtifactLink';
import { Message } from '../../services/chat.service';

export interface MyMessageProps {
  message: Message;
}
export function MyMessage({ message }: MyMessageProps) {
  return (
    <>
      {message.artifacts &&
        message.artifacts.length > 0 &&
        message.artifacts.map((artifact) => (
          <div key={`${message.id}:${artifact.id}`} className='img-container float-right' style={{ marginRight: '18px' }}>
            <ArtifactLink artifact={artifact} preview={true} className='message-image' />
          </div>
        ))}
      {message.message && (
        <div className='float-right chat chat-end' style={{ marginRight: '18px' }}>
          <div className='max-w-sm pt-2.5 bg-red-700 ml-7 chat-bubble'>{message.message}</div>
        </div>
      )}
    </>
  );
}

export default MyMessage;
