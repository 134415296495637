import React, { useState } from 'react';
import Form from '../../components/TicketForm';
import ScrollToTop from '../../components/ScrollToTop';
import TicketService from '../../services/ticket.service';
import { logger } from '../../lib/logger';

import './newTicket.css';

const NewProject = () => {
  const [projectType, setProjectType] = useState('');
  const [activeForm, setActiveForm] = useState('Begin');
  const [forms, setForms] = useState({});
  const [values, setValues] = useState({ name: '', description: '' });
  const [isModalActive, setIsModalActive] = useState('false');
  // const [hasChanged, setHasChanged] = useState(false);

  //TODO: save to local storage to retrieve on reload
  const handleInput = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onSubmitTicket = (e) => {
    e.preventDefault();
    setActiveForm('General');
  };

  const onSubmitGeneral = (formName, formData) => {
    setForms({ ...forms, [formName]: formData });
    setActiveForm('Specialized');
  };

  const onSubmitOther = async (formName, formData) => {
    const f = { ...forms, [formName]: formData };
    setForms(f);

    await TicketService.createTicket(values.name, values.description, null, f)
      .then((res) => {
        setActiveForm('Confirmed');
      })
      .catch((err) => logger.error(err));
  };

  const ToggleModalClass = () => {
    setIsModalActive(!isModalActive);
  };

  // useEffect(() => {
  //   if (!hasChanged) {
  //     return;
  //   }
  //   function handleOnBeforeUnload(event) {
  //     event.preventDefault();
  //     return (event.returnValue = '');
  //   }

  //   window.addEventListener('beforeunload', handleOnBeforeUnload, { capture: true });

  //   return () => {
  //     window.removeEventListener('beforeunload', handleOnBeforeUnload, { capture: true });
  //   };
  // }, [hasChanged]);

  // function handleOnChange() {
  //   setHasChanged(true);
  // }

  let content;
  switch (activeForm) {
    case 'Begin':
      content = (
        <>
          <h2 className='text-lg font-semibold'>What Can We Help You With?</h2>
          <form onSubmit={onSubmitTicket} className='flex flex-col items-center justify-center gap-4'>
            <div className='w-full max-w-xs form-control'>
              <label htmlFor='project' className='label'>
                <span className='label-text'>*Project Name</span>
              </label>
              <input
                onChange={handleInput}
                type='text'
                id='project'
                name='name'
                placeholder='Project Name'
                className='text-zinc-800 w-full max-w-xs bg-[#fef2f2] inset-field input input-bordered'
                required
              />
            </div>
            <div className='w-full max-w-xs form-control'>
              <label htmlFor='description' className='label'>
                <span className='label-text'>Project Description</span>
              </label>
              <textarea
                onChange={handleInput}
                id='description'
                name='description'
                placeholder='Project Description'
                className='w-full text-[16px] max-w-xs min-h-[10rem] text-zinc-800 inset-field textarea textarea-bordered bg-[#fef2f2]'
              />
            </div>
            <div className='my-6'>
              {/* <h3>Pick A Category</h3> */}
              <span className='label label-text'>*Pick A Category</span>
              <div id='options' className='gap-[2px] join'>
                <input
                  onClick={(e) => {
                    setProjectType('Video');
                  }}
                  className='btn btn-error join-item'
                  type='radio'
                  name='options'
                  aria-label='Video'
                  required
                />
                {/* Video */}
                <input
                  onClick={() => {
                    setProjectType('Audio');
                  }}
                  className='btn btn-error join-item'
                  type='radio'
                  name='options'
                  aria-label='Audio'
                  required
                />
                {/* Audio */}
                <input
                  onClick={() => {
                    setProjectType('Lighting');
                  }}
                  className='btn btn-error join-item'
                  type='radio'
                  name='options'
                  aria-label='Lights'
                  required
                />
                {/* Lighting */}
              </div>
            </div>
            <button type='submit' className='bg-red-600 hover:bg-red-700 btn btn-wide'>
              Continue
            </button>
          </form>
        </>
      );
      break;
    case 'General':
      content = (
        <>
          <div className='divider'></div>
          <h2 className='mb-4 text-2xl font-semibold'>General Questions</h2>
          <Form name='General' onSubmit={onSubmitGeneral} submitText='Continue' />
        </>
      );
      break;
    case 'Specialized':
      content = (
        <>
          <div className='divider'></div>
          <h2 className='mb-4 text-2xl font-semibold'>{projectType} Questions</h2>
          <Form name={projectType} onSubmit={onSubmitOther} submitText='Submit' />
        </>
      );
      break;
    case 'Confirmed':
      content = (
        // <div className='bg-white flex-center'>
        //   <div className='divider'></div>

        //   <div className='w-[80%] border'>
        //     <p>
        //       Thank you for your submission. Your next step is to look at the tasks you have been assigned. You will find
        //       this on the homepage
        //     </p>
        //   </div>
        // </div>
        <>
          {/* Open the modal using document.getElementById('ID').showModal() method */}
          {/* <button className='btn' onClick={() => document.getElementById('my_modal_5').showModal()}>
            open modal
          </button> */}
          <dialog
            id='my_modal_5'
            className={`${isModalActive ? 'modal-open' : 'modal-close'} modal modal-bottom sm:modal-middle `}
          >
            <div className='bg-white modal-box'>
              <h3 className='text-lg font-bold'>Congratulations!</h3>
              <p className='py-4'>
                Your project has been submitted and will be reviewed soon. In the meantime, we have added some tasks for you
                to complete to help you get to know your way around. Check the &quot;Task List&quot; tab to get started!
              </p>
              <div className='modal-action'>
                <form method='dialog'>
                  {/* if there is a button in form, it will close the modal */}
                  <button onClick={ToggleModalClass} className='btn bg-slate-900'>
                    Close
                  </button>
                </form>
              </div>
            </div>
          </dialog>
        </>
      );
      break;
    default:
      content = null;
      break;
  }
  // eslint-disable-next-line no-console
  console.log('activeForm', activeForm);

  return (
    <section className='flex flex-col items-center justify-start flex-1 gap-8 mx-2 mt-4 text-center'>
      <ScrollToTop />
      <div className='container'>
        <iframe
          allowFullScreen
          className='responsive-iframe '
          src='https://www.youtube.com/embed/m-GmGo2A1B8'
          // src='https://youtu.be/m-GmGo2A1B8'
          title='Event Tech AV - Who We Are'
          // frameborder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
        ></iframe>
      </div>

      <div className='divider w-[80%] self-center'></div>
      <div className='flex-col w-full p-4 mb-4 '>
        <h2 className='text-3xl font-bold'>Start Your New Project</h2>
        <br />
        <div>{content}</div>
      </div>
    </section>
  );
};

export default NewProject;
