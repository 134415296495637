import { AxiosResponse } from 'axios';
import moment from 'moment';
import { axiosPublic } from './api.service';

export interface Token {
  token: string;
  expires: string;
  issued?: string;
}

export interface TokenResponse {
  access: Token;
  refresh?: Token;
}

class AuthService {
  // Check if the current user is logged in
  static isLoggedIn(): boolean {
    const accessToken = this.getAccessToken();
    return !!accessToken && moment().isBefore(accessToken.expires);
  }

  // Refresh the access token if the refresh token is still valid
  static async refreshLogin(this: void): Promise<void> {
    const refreshToken = AuthService.getRefreshToken();
    if (refreshToken) {
      const refreshTokenKey = refreshToken.token;
      return axiosPublic
        .post<unknown, AxiosResponse<TokenResponse, unknown>>('auth/refresh-tokens', { refreshToken: refreshTokenKey })
        .then((response) => {
          if (response.data) {
            AuthService.setAccessToken(response.data.access);
            AuthService.setRefreshToken(response.data.refresh);
          }
        });
    }
    return Promise.resolve();
  }

  // Return the JWT access token for the logged in user
  static getAccessToken(): Token | undefined {
    const tokenString = localStorage.getItem('accessToken');
    if (tokenString) {
      return JSON.parse(tokenString) as Token;
    }
    return undefined;
  }

  // Set the JWT access token for the logged in user
  static setAccessToken(token: Token | undefined) {
    if (token) {
      localStorage.setItem('accessToken', JSON.stringify(token));
    } else {
      localStorage.removeItem('accessToken');
    }
  }

  // Return the JWT refresh token for the logged in user
  static getRefreshToken(): Token | undefined {
    const tokenString = localStorage.getItem('refreshToken');
    if (tokenString) {
      return JSON.parse(tokenString) as Token;
    }
    return undefined;
  }

  // Set the JWT refresh token for the logged in user
  static setRefreshToken(token: Token | undefined) {
    if (token) {
      localStorage.setItem('refreshToken', JSON.stringify(token));
    } else {
      localStorage.removeItem('refreshToken');
    }
  }
}

export default AuthService;
