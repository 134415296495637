type FileOptions = 'any' | 'video' | 'picture' | 'pdf' | 'document';

const fileFilter = (options?: string[]): string => {
  let filter = '';
  options?.forEach((option) => {
    switch (option as FileOptions) {
      case 'video':
        filter += ',video/*';
        break;
      case 'picture':
        filter += ',image/*';
        break;
      case 'pdf':
        filter += ',application/pdf';
        break;
      case 'document':
        filter +=
          ',application/pdf' +
          ',.doc,.docx,.xml,application/msword' +
          ',application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        break;
      default:
        break;
    }
  });
  if (filter.startsWith(',')) {
    filter = filter.slice(1);
  }
  return filter;
};

export default fileFilter;
