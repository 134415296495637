import React, { useEffect, useState } from 'react';
import ArtifactService, { Artifact } from '../services/artifact.service';
import { logger } from '../lib/logger';

export interface ArtifactLinkProps {
  artifact: Artifact | string;
  preview?: boolean;
  className?: string;
}

export function ArtifactLink({ artifact, preview, className }: ArtifactLinkProps) {
  const [src, setSrc] = useState<string>();
  const [name, setName] = useState<string>();
  const [artifact_, setArtifact] = useState<Artifact>();

  useEffect(() => {
    if (typeof artifact === 'string') {
      ArtifactService.getArtifact(artifact)
        .then((response) => {
          setArtifact(response);
        })
        .catch((err) => logger.error(err));
    } else {
      setArtifact(artifact);
    }
  }, [artifact]);

  const downloadArtifact = () => {
    if (!artifact_?.file) {
      return;
    }
    ArtifactService.getArtifactFile(artifact_.id)
      .then((response) => {
        // Create a new blob object
        const downloadUrl = URL.createObjectURL(response.blob);

        const link = document.createElement('a');
        link.href = downloadUrl;

        // set the download attribute (supported in Chrome, Firefox, and Opera)
        link.download = response.fileName || artifact_.id;

        // trigger the download by simulating click
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => logger.error(err));
  };

  useEffect(() => {
    if (!artifact_) {
      setName(undefined);

      return;
    }
    if (!preview) {
      setName(artifact_.file?.name);
      return;
    }
    if (artifact_.thumb && artifact_.thumb.mime.startsWith('image/')) {
      ArtifactService.getArtifactThumb(artifact_.id)
        .then((response) => {
          const blobUrl = URL.createObjectURL(response.blob);
          setName(response.fileName);
          setSrc(blobUrl);
        })
        .catch((err) => logger.error(err));
    } else if (artifact_.file && artifact_.file.mime.startsWith('image/')) {
      ArtifactService.getArtifactFile(artifact_.id)
        .then((response) => {
          const blobUrl = URL.createObjectURL(response.blob);

          setName(response.fileName);
          setSrc(blobUrl);
        })
        .catch((err) => logger.error(err));
    } else {
      setName(artifact_.file?.name);
    }
  }, [artifact_, preview]);

  const artifactButton = <span className={className}>Attachment: {name || 'Invalid'}</span>;
  const empty = <span className={className}>{artifact_ ? 'No File' : ''}</span>;
  const artifactPreview = <img src={src} title={name} alt={name} className={className} />;

  return (
    <>
      {artifact_?.file ? (
        <>
          <div onClick={downloadArtifact}>{src ? artifactPreview : artifactButton}</div>
          {src ? <h3>{artifact_?.file?.name}</h3> : null}
        </>
      ) : (
        empty
      )}
    </>
  );
}
