import { AxiosResponse } from 'axios';
import { axiosPublic, axiosPrivate, ResultList } from './api.service';
import AuthService, { Token } from './auth.service';

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  displayName: string;
  role: string;
}

export interface LoginResponse {
  user: User;
  tokens: {
    access: Token;
    refresh?: Token;
  };
}

class UserService {
  static oAuthLogin(window: Window, loginUrl: string): void {
    window.open(`${loginUrl}?redirect=${window.location.href}`, '_self');
  }

  // This function can be called in a useEffect hook or at an appropriate place in your component.
  static oAuthCallback(window: Window) {
    // Check if the user has been redirected back with an authorization code
    const urlParams = new URLSearchParams(window.location.search);
    const auth = urlParams.get('#auth');

    if (auth) {
      const json = atob(auth);
      const data = JSON.parse(json) as LoginResponse;
      UserService.#setCurrentUser(data.user);
      if (data.tokens) {
        AuthService.setAccessToken(data.tokens.access);
        AuthService.setRefreshToken(data.tokens.refresh);
      }
    }
    return UserService.getCurrentUser();
  }

  // Log in a user
  // @param email - Email associated with this user
  // @param password - Password for this user
  static async login(email: string, password: string): Promise<User> {
    return axiosPublic
      .post<unknown, AxiosResponse<LoginResponse, unknown>>('auth/login', {
        email,
        password,
      })
      .then((response: AxiosResponse<LoginResponse, unknown>) => {
        UserService.#setCurrentUser(response.data.user);
        if (response.data.tokens) {
          AuthService.setAccessToken(response.data.tokens.access);
          AuthService.setRefreshToken(response.data.tokens.refresh);
        }
        return Promise.resolve(response.data.user);
      });
  }

  // Log out the current logged in user
  static logout(): Promise<void> {
    const clearUser = () => {
      UserService.#setCurrentUser(undefined);
      AuthService.setAccessToken(undefined);
      AuthService.setRefreshToken(undefined);
    };

    const refreshToken = AuthService.getRefreshToken();
    if (refreshToken) {
      return axiosPublic
        .post('auth/logout', {
          refreshToken: refreshToken.token,
        })
        .then(clearUser);
    }
    clearUser();
    return Promise.resolve();
  }

  // Return the current logged in user
  static getCurrentUser(): User | undefined {
    if (AuthService.isLoggedIn()) {
      const userString = localStorage.getItem('user');
      if (userString) {
        return JSON.parse(userString) as User;
      }
    }
    return undefined;
  }

  // Set the current logged in user
  // @param User data returned from login endpoint
  static #setCurrentUser(user: User | undefined) {
    if (user) {
      localStorage.setItem('user', JSON.stringify(user));
    } else {
      localStorage.removeItem('user');
    }
  }

  // Get list of users
  // @param company - Company name of the user to use as a filter
  // @param firstName - First name of the user to use as a filter
  // @param lastName - Last name of the user to use as a filter
  // @param role - User role to use as a filter
  // @param sortField - Field to sort results by
  // @param sortOrder - Order of sorting (only if sortField supplied)
  // @limit - Maximum number of results per page
  // @page - Page of results to return
  static getUsers(
    company?: string,
    firstName?: string,
    lastName?: string,
    role?: string,
    limit?: number,
    page?: number,
    sortField?: string,
    sortOrder = 'asc'
  ): Promise<ResultList<User>> {
    return axiosPrivate
      .get('users', {
        params: {
          company: company || undefined,
          firstName: firstName || undefined,
          lastName: lastName || undefined,
          role: role || undefined,
          sortBy: sortField ? `${sortField}:${sortOrder}` : undefined,
          limit: limit || undefined,
          page: page || undefined,
        },
      })
      .then((response) => response.data as ResultList<User>);
  }

  // Get a specific user
  // @param id - ID of user to retrieve
  static getUser(id: string): Promise<User> {
    return axiosPrivate.get(`users/${id}`).then((response) => response.data as User);
  }
}

export default UserService;
