import React from 'react';
import useAuth from '../../hooks/useAuth';
import Tickets from './components/Tickets';
import { Link } from 'react-router-dom';

const Admin = () => {
  const user = useAuth()?.user;

  return (
    <div className='flex flex-col items-center my-6'>
      <h1 className='mb-2 text-lg md:mb-6'>Active Projects</h1>
      <div className='divider w-[80%] self-center'></div>

      <Tickets />
      {user?.role === 'admin' && (
        <Link className='btn btn-wide btn-secondary' to='/admin/forms'>
          Update Forms
        </Link>
      )}
    </div>
  );
};

export default Admin;
