import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import UserService from '../services/user.service';
import Avatar from '../components/Avatar';
import logo from '../assets/images/logo2023.png';
import { logger } from '../lib/logger';

const Nav = () => {
  const setUser = useAuth()?.setUser;
  const user = useAuth()?.user;
  const navigate = useNavigate();

  const handleClick = () => {
    const elem = document.activeElement;

    if (elem) {
      elem?.blur();
    }
  };

  const logout = () => {
    UserService.logout()
      .then(() => {
        if (setUser) {
          setUser(undefined);
        }
        navigate('/login');
      })
      .catch((err) => {
        logger.error('Logout Failed', err);
      });
  };

  const handleToggle = () => {
    // const temp = document.getElementById('dropdown-left')?.classList;
    // temp.toggle('hidden');
  };

  return (
    <div className='justify-center navbar bg-[#FF0000]'>
      {user?.role ? (
        <div className='navbar-start'>
          <div className='z-10 dropdown' onClick={handleToggle} style={{ filter: 'drop-shadow(0 0 0.15rem #8e8e8e)' }}>
            <label tabIndex={0} className='btn btn-ghost btn-rounded'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='w-5 h-5'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
              >
                <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M4 6h16M4 12h16M4 18h7' />
              </svg>
            </label>
            <ul
              tabIndex={0}
              id='dropdown-left'
              className='p-2 mt-3 shadow menu menu-sm dropdown-content bg-base-100 rounded-box w-52'
            >
              <li className='mb-1' onClick={handleClick}>
                <Link to='/'>Home</Link>
              </li>
              {user.role === 'user' && (
                <li className='mb-1' onClick={handleClick}>
                  <Link to='/newproject'>New Project</Link>
                </li>
              )}
              <li className='mb-1' onClick={handleClick}>
                <Link to='/tasklist'>Task List</Link>
              </li>
              <li className='mb-1' onClick={handleClick}>
                <Link to='/chat'>Chat</Link>
              </li>
              <li className='mb-1' onClick={handleClick}>
                <Link to='/meeting'>Join Meeting</Link>
              </li>
              <li onClick={handleClick}>
                <Link to='/videolibrary'>Video Library</Link>
              </li>
            </ul>
          </div>
        </div>
      ) : (
        user && <div className='navbar-start' />
      )}
      <div className='navbar-center'>
        <Link to='/' className='text-xl normal-case btn btn-ghost'>
          <img
            className='max-w-[85%] max-h-[85%]  md:max-w-[95%] md:max-h-[95%] rounded-lg'
            src={logo}
            alt='The AV App Logo'
          />
        </Link>
      </div>
      {user && (
        <div className='navbar-end'>
          <div className='z-10 dropdown dropdown-end' style={{ filter: 'drop-shadow(0 0 0.15rem #8e8e8e)' }}>
            <label tabIndex={0} className='btn btn-ghost btn-rounded avatar'>
              <Avatar firstName={user.firstName} lastName={user.lastName} role={user.role} />
            </label>
            <ul tabIndex={0} className='p-2 mt-3 shadow menu menu-sm dropdown-content bg-base-100 rounded-box w-52'>
              {['tech', 'admin'].includes(user.role) ? (
                <li onClick={handleClick}>
                  <Link to='/admin' className='justify-between mb-1'>
                    Admin
                  </Link>
                </li>
              ) : null}
              <li onClick={handleClick}>
                <button onClick={logout}>Logout</button>
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default Nav;
