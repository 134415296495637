import React, { useState, useEffect } from 'react';
import TicketService from '../../../services/ticket.service';
import TaskService from '../../../services/task.service';
import useAuth from '../../../hooks/useAuth';
import Ticket from './Ticket';
import Modal from './Modal';
import { logger } from '../../../lib/logger';
import ScrollToTop from '../../../components/ScrollToTop';

const Tickets = () => {
  const [tickets, setTickets] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [activeTicket, setActiveTicket] = useState(null);
  const [ticketDeleted, setTicketDeleted] = useState(false);
  const user = useAuth()?.user;

  useEffect(() => {
    TicketService.getTickets()
      .then((ticketPages) => {
        logger.log('ticketPages before sort', ticketPages);
        ticketPages.results.sort((a, b) => a.createdAt - b.createdAt).reverse();
        logger.log('ticketPages after sort', ticketPages);

        setTickets(ticketPages.results);
      })
      .catch((err) => logger.error(err));

    return () => {
      setTicketDeleted(false);
    };
  }, [ticketDeleted]);

  useEffect(() => {
    const getTasks = async () => {
      await TaskService.getTasks()
        .then((taskPages) => {
          setTasks(taskPages.results);
        })
        .catch((err) => logger.error(err));
    };

    getTasks();
  }, []);

  const updateTicket = (updatedTicket) => {
    setTickets(
      tickets.map((ticket) => {
        return ticket.id === updatedTicket.id ? updatedTicket : ticket;
      })
    );
    setActiveTicket(null);
  };

  const deleteTicket = (ticket) => {
    tasks.forEach((task) => {
      if (task.ticket === ticket.id) {
        TaskService.deleteTask(task.id);
      }
    });

    TicketService.deleteTicket(ticket.id).then((res) => {
      setTicketDeleted(true);
    });
  };

  return (
    <div className='flex flex-col items-center w-full xl:w-[60%] max-h-full my-6 md:my-12'>
      <ScrollToTop />
      {tickets.map((ticket) => {
        return (
          <div className='mb-12 place-items-center flex flex-col w-[95%]' key={ticket.id}>
            <div className='flex flex-col w-full'>
              <div className='flex items-center justify-between mb-2'>
                <div className='max-w-[50%] flex flex-col'>
                  <h2 className=''>{ticket.name}</h2>
                </div>
                <div>
                  {user.role === 'admin' && (
                    <>
                      <button
                        className='mr-2 btn btn-sm btn-error'
                        onClick={() => {
                          setActiveTicket(ticket);
                          document.getElementById('delete-modal').showModal();
                        }}
                      >
                        delete
                      </button>
                      <dialog id='delete-modal' className='modal text-stone-200'>
                        <form method='dialog' className='modal-box '>
                          <h3 className='text-lg font-bold'>
                            Are you sure you want to delete: <br /> {activeTicket?.name}?
                          </h3>
                          <p className='py-4'>This action cannot be reversed!</p>
                          <div className='flex justify-between '>
                            <button className='btn btn-sm btn-info'>Cancel</button>
                            <button className='btn btn-sm btn-error' onClick={() => deleteTicket(activeTicket)}>
                              Confirm Delete
                            </button>
                          </div>
                        </form>
                        <form method='dialog' className='modal-backdrop'>
                          <button className='w-[100vw] h-[100vh] bg-[#000000b2]'>close</button>
                        </form>
                      </dialog>
                    </>
                  )}
                  <button
                    className='btn btn-sm btn-success'
                    onClick={() => {
                      setActiveTicket(ticket);
                      document.getElementById('update-ticket-modal').showModal();
                    }}
                  >
                    {user.role === 'admin' ? 'Update' : 'View Attachments'}
                  </button>
                </div>
              </div>
              <div className='overflow-x-auto border-2 border-[#cdced0a9] rounded bg-neutral'>
                <Ticket ticket={ticket} />
              </div>
            </div>
          </div>
        );
      })}
      <Modal
        id={'update-ticket-modal'}
        ticket={activeTicket}
        updateTicket={updateTicket}
        onCancel={() => {
          setActiveTicket(null);
        }}
      />
    </div>
  );
};

export default Tickets;
