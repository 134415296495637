import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import mem from 'mem';
import AuthService from './auth.service';

export interface ResultList<Type> {
  results: Type[];
  page: number;
  limit: number;
  totalPages: number;
  totalResults: number;
}

export const API_URL = process.env.REACT_APP_API_URL || '/v1';
const TOKEN_REFRESH_MIN = 20000; // Minimum interval which token can be refreshed

// Used for accessing unauthenticated API urls
export const axiosPublic = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Used for accessing private API urls with a JWT access token
export const axiosPrivate = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Request interceptor adds the Bearer auth token to private API requests
axiosPrivate.interceptors.request.use(
  (config) => {
    const accessToken = AuthService.getAccessToken();
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken.token}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor checks for an auth failure and refreshes the token before resending the original request
const memoizedRefreshToken = mem(AuthService.refreshLogin, { maxAge: TOKEN_REFRESH_MIN });
axiosPrivate.interceptors.response.use(
  (response) => response,
  async (error: AxiosError<unknown, unknown>) => {
    if (error?.response?.status === 401 && error.config && (error.config as { sent?: boolean }).sent) {
      (error.config as { sent?: boolean }).sent = true;

      await memoizedRefreshToken();
      return axiosPrivate(error.config);
    }
    return Promise.reject(error);
  }
);

axiosPrivate.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse => response,
  async (error: AxiosError): Promise<unknown> => {
    const originalRequest = error.config as AxiosRequestConfig & { _retry?: boolean };
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        await memoizedRefreshToken();

        const accessToken = AuthService.getAccessToken();
        if (!originalRequest.headers) {
          originalRequest.headers = {};
        }
        if (accessToken) {
          originalRequest.headers.Authorization = `Bearer ${accessToken.token}`;
        }

        return axiosPrivate(originalRequest);
      } catch (refreshError) {
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);
