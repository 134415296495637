import React from 'react';
import useAuth from '../../hooks/useAuth';
import UserTables from '../../components/UserTables';
import TechTables from '../../components/TechTables';
import ScrollToTop from '../../components/ScrollToTop';

import './taskList.css';

//TODO: MAYBE I CAN CALL TICKETS AND TASKS HERE AND PASS DOWN AS PROPS???

export default function TaskList() {
  const user = useAuth()?.user;
  const headerMessage =
    user.role === 'admin' ? 'All Projects' : user.role === 'tech' ? 'Your Projects' : 'Your Project Tasks';

  return (
    <div className='flex flex-col max-h-full my-6 md:my-12 place-items-center'>
      <ScrollToTop />
      <h1 className='mb-2 text-lg md:mb-6'>{headerMessage}</h1>
      {user?.role === 'user' ? <UserTables user={user} /> : <TechTables user={user} />}
    </div>
  );
}
