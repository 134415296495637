import React, { useState, useEffect } from 'react';
import FormField from './FormField';
import { Category, FormData, Form as FormType } from '../services/form.service';

export interface FormProps {
  form: FormType;
  name: string;
  formData?: Record<string, FormData>;
  onFieldChange?: (id: string, value: FormData) => void;
}

const Form: React.FC<FormProps> = ({ form, name, formData, onFieldChange }) => {
  const [categories, setCategories] = useState<Category[]>([]);

  useEffect(() => {
    if (!form) {
      // eslint-disable-next-line no-console
      console.log('No form found');
      return;
    }
    form.categories?.forEach((category) => {
      category.fields.forEach((field) => {
        let initialValue: FormData = undefined;
        if (field.defaultValue !== undefined) {
          initialValue = field.defaultValue as FormData;
        } else if (field.type === 'multiple') {
          initialValue = [];
        } else if (field.type === 'checkbox') {
          initialValue = false;
        } else {
          initialValue = '';
        }
        if (onFieldChange) {
          onFieldChange(field.id, initialValue);
          field.conditionals?.forEach((conditional) => {
            onFieldChange(conditional.id, conditional.defaultValue !== undefined ? conditional.defaultValue : '');
          });
        }
      });
    });
    setCategories(form.categories);
  }, [form, onFieldChange]);

  //TODO: ?? create another useEffect to retrieve local storage values on page load if present ??
  // create useEffect to retrieve local storage values on page load if present
  // useEffect(() => {
  //   const storedData: Record<string, FormData> = {};
  //   categories.forEach((category) => {
  //     category.fields.forEach((field) => {
  //       if (field.defaultValue !== undefined) {
  //         storedData[field.id] = field.defaultValue as FormData;
  //       } else if (field.type === 'multiple') {
  //         storedData[field.id] = [];
  //       } else if (field.type === 'checkbox') {
  //         storedData[field.id] = false;
  //       } else {
  //         storedData[field.id] = '';
  //       }
  //       field.conditionals?.forEach((conditional) => {
  //         storedData[conditional.id] = conditional.defaultValue !== undefined ? conditional.defaultValue : '';
  //       });
  //     });
  //   });
  //   setFormData(storedData);
  // }, [categories]);

  //* store changes to local storage here
  // const handleFieldChange = (id: string, value: FormData) => {
  //   setFormData((prevState) => ({ ...prevState, [id]: value }));
  //   window.localStorage.setItem(id, JSON.stringify(value));
  // };

  //TODO: delete saved local storage after onSubmit here
  // const handleSubmit = (event: React.FormEvent) => {
  //   event.preventDefault();
  //   if (!name) {
  //     return;
  //   }
  //   if (onSubmit) {
  //     onSubmit(name, formData);
  //     setSubmitted(true);
  //   }
  // };

  return (
    <>
      {categories.map((category, i) => (
        <div key={`${name}.${form.name}.${category.title}`} className='collapse form-category place-items-center'>
          <input type='checkbox' name={form.name + '-accordian'} />
          <h2 className='max-w-xs p-0 m-0 mb-3 text-xl font-bold text-center collapse-title'>
            {!category.title || category.title === 'default' ? 'Project Info' : category.title}
          </h2>
          <div className='flex flex-col items-center w-full max-w-96 collapse-content'>
            {category.fields.map((field, j) => (
              <FormField
                key={j}
                field={field}
                onFieldChange={onFieldChange}
                formData={formData}
                readOnly={form?.submitted || false} //
              />
            ))}
          </div>
          {i !== categories.length - 1 && <div className='divider'></div>}
        </div>
      ))}
    </>
  );
};

export default Form;
